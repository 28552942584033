// for Index swiper effect
$color_1: #fff0;
$font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: $OpenSans-300;
    --fancy-slider-title-line-height: 1;

    /* Card */
    --card-transition-duration: 500ms;
    --card-transition-delay: 250ms;    /* delay background color */
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 1;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */

    // Video
    --bs-aspect-ratio-mobile: 100%
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.index{
    // 內框
    &-content{
        padding-top: 2em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            padding: 1.5em 1em;
        }

        // 
        .btn-wrap{
            @include setMinRwd{
                width: auto;
                margin-top: 0;
                justify-content: start;
            }
        }

        .btn{
            flex: 1;
            max-width: 200px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            margin: 0.75em 1em 0.75em 0;

            @include setMinRwd{
                justify-content: flex-start;
                padding-top: 0;
                margin: 0;
            }

            &:hover{
                color: $colorMain !important;
                border-color: transparent !important;
                background-color: transparent !important;
            }
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                width: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

        &Swiper{
            width: 100%;
            overflow: hidden;
            .swiper-wrapper{
                /* 通过改变animation-timing-function 制作弹性切换效果 */
                transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
            }

            // 共用
            .swiper-button-wrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                top: -2em;
                .swiper-button-prev,
                .swiper-button-next{
                    @include setSize(47px,47px);
                    overflow: hidden;
                    
                    &::after {
                        display: none;
                    }

                    svg{
                        path{
                            fill: $colorMain;
                        }
                    }

                    &.swiper-button-disabled {
                        svg{
                            path{
                                fill: #bfbfbf;
                            }
                        }
                    }

                    @include setMinRwd{
                        position: static;
                    }
                }
            }
        }

        // 標題用
        &Head{
            
        }

        // 內文用
        &Article{
            line-height: 1.5;
        }

        &About{
            padding-top: get-vw(50px);
            padding-bottom: get-vw(60px);

            .pageGrid{
                width: 100%;
                align-items: center;
                grid-template-columns: 1fr 1fr;

            }

            .pageAbout-img{
                &-left{
                    margin-top: get-vw(195px);

                    @include setMaxRwd{
                        margin-top: 0;
                        margin-bottom: 1em;
                    }
                }

                &-right{
                    margin-top: get-vw(150px);
                    margin-left: auto;
                    .icon{
                        &-animated{
                            &-top{
                                position: absolute;
                                top: 2em;
                                transform-origin: center;
                                animation: animatedTop 3s infinite;

                                @include setStyleRwd(1300px){
                                    left: -1em;
                                    svg,image{
                                        width: 5em;
                                        height: 5em;
                                    }
                                }
                            }

                            &-bottom{
                                position: absolute;
                                bottom: 2em;
                                left: 0;
                                animation: animatedBottom 3s infinite;

                                @include setStyleRwd(1300px){
                                    bottom: 0;
                                    svg,image{
                                        width: 5em;
                                        height: 5em;
                                    }
                                }
                            }
                        }
                    }

                    @include setMaxRwd{
                        margin-right: -2em;
                    }
                }

                @include setWidthRwd(1601px,1700px){
                    &-left,
                    &-right{
                        max-width: 40em;
                    }
                }

                @include setWidthRwd(1301px,1599px){
                    &-left,
                    &-right{
                        max-width: 30em;
                    }
                }

                @include setWidthRwd(1100px,1300px){
                    &-left,
                    &-right{
                        max-width: 25em;
                    }
                }
            }

            @keyframes animatedTop {
                0%{
                    transform: rotate(0);
                }

                100%{
                    transform: rotate(75deg);
                }
            }

            @keyframes animatedBottom {
                0%{
                    opacity: 0;
                    bottom: 0;
                }

                100%{
                    opacity: 1;
                    bottom: 0;
                }
            }

            .pageAbout-info{
                .heading-title {
                    @include setMinRwd{
                        writing-mode: vertical-rl;
                        line-height: 0.25;
                        position: relative;
                        top: -2em;
                        left: 1em;
                        &-txt{
                            &,
                            &-en{
                                padding: 0;
                                letter-spacing: 2px;
                            }
    
                            &-en{
                                padding-top: 3px;
                            }
                        }
                    }
                }

                &-article{
                    &-head{
                        --min-size: 22;
                        --max-size: 36;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px); 
                        line-height: 2;

                        @include setMaxRwd{
                            margin-bottom: 0.25em;
                        }
                    }

                    .article{
                        --min-size: 16;
                        --max-size: 20;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    }
                    
                    @include setMinRwd{
                        writing-mode: vertical-rl;
                        padding-left: get-vw(40px);
                        .article{
                            width: auto;
                            margin-bottom: 4em;
                            line-height: 2.75;
                        }
                    }
                }

                @include setMinRwd{
                    position: absolute;
                    width: 100%;
                    top: 10%;
                    left: 50%;
                    max-width: 30em;
                    margin-left: -15em;
                }
            }

            .btn{
                @include setMinRwd{
                    .icon{
                        margin-top: 15px;
                    }
                }
            }

            @include setMaxRwd{
                .pageGrid{
                    display: block;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        &News{
            @include setBackSet(top,cover);
            background-image: url(../images/index-pageNews-bg.png);
            padding-top: get-vw(90px);
            padding-bottom: get-vw(100px);
            margin-bottom: 1em;

            .heading-title{
                position: relative;
                z-index: 1;
            }

            .heading-img{
                margin-top: -3.9em;
                z-index: 0;

                @include setMinRwd{
                    left: -2.25em;
                }
            }

            .btn-wrap{
                @include setMinRwd{
                    &, .btn{
                        justify-content: flex-end;
                    }

                    margin-right: 1.25em;
                    margin-top: 1.25em;

                    .btn{
                        padding-right: 0;
                    }
                }
            }

            .btn{
                .icon{
                    margin-right: 15px;
                }
            }

            .index-pageNews-list{
                .list-group-item{
                    padding-top: 1em !important;
                    padding-bottom: 1em !important;
                    
                    .time,
                    .txt{
                        --min-size: 15;
                        --max-size: 16;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    }

                    .time{
                        color: #7e7e7e;
                    }
                    
                }

                @include setMaxRwd{
                    margin-top: 1em;
                }
            }

            @include setMaxRwd{
                padding-top: get-vw-mobile(80px);
            }
        }

        &PlantTech{
            padding-top: get-vw(40px);
            padding-bottom: get-vw(80px);

            @include setMinRwd{
                .pageGrid{
                    align-items: center;
                    &:nth-child(3){
                        .index-pagePlantTech-img{
                            width: calc(100% + 30%);
                            margin-left: -20%;
                        }
                    }
                }
            }

            &-img{
                margin-bottom: 1em;
            }

            &-info{
                padding-left: get-vw(60px);
                padding-right: get-vw(60px);

                &-head{
                    margin-bottom: get-vw(40px);
                    &Subtitle{
                        font-size: 20px;
                        text-transform: uppercase;
                        font-family: $Fira, Arial;
                        letter-spacing: 1px;
                        &::after{
                            background-color: currentColor;
                        }
                    }

                    &Title{
                        --min-size: 20;
                        --max-size: 24;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    }
                }

                &-article,
                &-step{
                    margin-bottom: get-vw(35px);
                }

                &-article{
                    --min-size: 16;
                    --max-size: 18;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    line-height: 1.85;
                }

                &-step{
                    display: inline-block;
                    background-color: #f4f0ec;
                    padding: 16px 20px;
                    @include radius(1.75em);
                    line-height: 1.25;
                    .icon{
                        margin-left: 5px;
                        margin-right: 5px;
                    }

                    @include setMinRwd{
                        padding-right: 4em;
                    }

                    @include setMaxRwd{
                        width: 100%;
                    }
                }
            }

            .btn{
                flex: none;
                color:#fff;
                border-radius: 1em !important;
                padding-top: 7px;
                .icon{
                    margin-top: -5px;
                    margin-right: 10px;
                }

                &:hover{
                    background-color: $colorMain !important;
                    color: white !important
                }
            }
        }

        &Resume{
            @include setBackSet(top,cover);
            background-image: url('../images/index-pageResume-bg.jpg');

            .titlebox{
                @include setMinRwd{
                    margin-top: 2em;
                }
            }

            .card{
                flex: 0 0 100% !important;
                max-width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                background-color: transparent;
                &-img-top{
                    text-align: center;
                    background: #fff;
                    padding: 15px;
                    img{
                        display: inline-block;
                        width: auto;
                    }
                }

                &-caption{
                    text-align: center;
                    @include setMinRwd{
                        margin-top: 0;
                    }
                }

                .icon{
                    margin-left: 10px;
                }
            }

            .pageFlex{
                flex-wrap: nowrap;
                justify-content: space-between;
                .titlebox{
                    flex: none;
                }

                @include setMaxRwd{
                    flex-direction: column;
                }
            }

            .swiper-container{
                max-width: 680px;
                margin-left: auto;
                overflow: hidden;
            }

            .swiper-button{
                width: 130px;
                display: flex;
                justify-content: space-between;
                margin: 2em auto auto;

                &-prev,
                &-next{
                    position: static;
                    width: 100%;
                    max-width: 55px;
                    height: 55px;
                    @include radius(50%);
                    border: #8cbb59 1px solid;

                    path{
                        fill: #8cbb59;
                    }

                    &::after{
                        display: none;
                    }
                }

                &-disabled{
                    opacity: 1 !important;
                    border-color: #7d7d7d;

                    path{
                        fill: #7d7d7d;
                    }
                }

                @include setMinRwd{
                    position: absolute;
                    left: 0em;
                    top: 10em;
                    margin-top: 0;
                }
            }

            &-bottom{
                bottom: -1.5em;
                @include setMinRwd{
                    position: absolute;
                    bottom: 0;
                }

                @include setWidthRwd(1200px,1600px){
                    max-width:36em;
                }
            }

            @include setMinRwd{
                padding-top: 3em;
                padding-bottom: 0;
            }

            @include setWidthRwd(1200px,1600px){
                padding-left: 3em;
                padding-right: 4em;
            }
        }
    }
}

.page{
    // 共用Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &.index{
            // height: 100%;
            @include setMinRwd{
                .fancy-slider-title{
                    position: absolute;
                }

                .fancy-slider-scale{
                    position: relative;
                }
            }

            @include setMaxRwd{
               height: get-vw-mobile(300px);
            }
        }

        &-main{
            @include mobile{
                img{
                    height: 6em;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
    
    // 共用Swiper
    &-gallerSwiper{
        overflow: hidden;
        // swiper 樣式
        .swiper{
            &-pagination{
                width: fit-content;
                display: inline-flex;
                align-items: center;
                gap: 8px;
                &-bullet {
                    @include setSize(50px,5px);
                    @include radius(0);
                    margin: 0 !important;
                    &-active {
                        background-color: $colorMain;
                    }
                }
            }
        }

        // 卡片內容
        .card{
            &-body{
                padding: 0;
            }

            &-title{
                font-weight: 400;
                line-height: 1.15;
                word-wrap: normal;
            }

            &-img-top{
                text-align: center;
                img{
                    display: inline-block;
                }
            }
        }

        .swiper{
            &-pagination{
                bottom: get-vw(10px) !important;

                @include setStyleMinRwd(760px){
                    &-bullet {
                        opacity: 0.2;
                        &-active{
                            opacity: 1;
                        }
                    }
                }

                @include setMaxRwd{
                    justify-content: center; 
                }
            }
        }

        // 首頁產品分類 swiper
        &-pro{
            width: 100%;
            padding-top: get-vw(15px);
            padding-bottom: get-vw(40px);
            .card{
                &-body{
                    @include setSize(100%,100%);

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include setMinRwd{
                        position: absolute;
                        padding: 3em;
                        color: #fff;
                        background-color: rgba(49, 49, 49,0.85);
                    }
                }

                &-title{
                    @include fontSize(42px);
                    font-family: var(--fancy-slider-title-font-family);
                    border-bottom: $colorBase 1px solid;
                    text-align: center;
                    padding-bottom: 0.3em;
                    margin-bottom: 0.35em;

                    @include setMinRwd{
                        border-bottom-color: rgba(255,255,255,0.65); 
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }

                @include setMinRwd{
                    &-linkNormal{
                        & ~ .card-body{
                            opacity: 0;
                            transition: var(--card-transition-duration);
                            .card-title,
                            .card-des{
                                transition-duration: var(--card-transition-duration);
                                transition-delay: var(--card-transition-delay);
                            }
    
                            .card-title{
                                transform: translateY(-1000%);
                            }
    
                            .card-des{
                                transform: translateY(1000%);
                            }
                        }
    
                        &:hover{
                            & ~ .card-body{
                                opacity: 1;
                                .card-title,
                                .card-des{
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }

                @include pad{
                    flex-direction: row;
                    align-items: flex-start;
                    background-color: transparent;
                    
                    &-img-top{
                        @include radius(20px);
                        overflow: hidden;
                        flex-basis: 150px;
                        margin-top: 46px;
                    }

                    &-body{
                        padding-left: 1.25em;
                        padding-right: 0.25em;
                        flex: 0 0 calc(100% - 150px);
                    }

                    &-title{
                        @include fontSize(25px);
                    }
                }
            }

            .swiper{
                &-slide{
                    .card{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    
                    @include setMinRwd{
                        &-active,
                        &-next{
                            .card{
                                border-bottom-right-radius: 0 !important
                            }
                        }

                        &-active{
                            .card{
                                border-top-left-radius: 40%;
                            }
                        }

                        &-next{
                            .card{
                                @include radius(20px);
                            }
                        }

                        .card{
                            border-bottom-right-radius: 40%;
                            overflow: hidden;
                        }
                    }
                }

                &-act{
                    &-container{
                        @extend %setFlex;
                        align-items: flex-end;
                        justify-content: end;
                        margin-bottom: 2.75em;
                        padding-right: 1em;

                        .swiper-pagination {
                            position: relative;
                            width: auto;
                            top: 0px;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(100%,1px);
                                background-color: #cbcbcb;
                                position: absolute;
                                top: 25px;
                                z-index: -1;
                            }
                        }
                    }

                    &-button{
                        width: calc(3.5em + 10px);
                        padding-left: 10px;
                        @extend %setFlex-center;
                        justify-content: space-between;
                        .swiper-button-next,
                        .swiper-button-prev {
                            position: static;
                            color: $colorBase;
                            height: auto;
                            margin-top: 0;
                            &::after{
                                font-size: 14px;
                                @extend .fw-900
                            }
                        }
                    }
                }

                &-pagination{
                    &-fraction{
                        span{
                            color: #b9b9b9;
                            font-size: 15px;
                            font-family: $OpenSans-300;
                            padding: 0 2px;
                        }

                        .swiper-pagination-empty{
                            width: 30px;
                        }

                        .swiper-pagination-current{
                            position: relative;
                            color: $colorBase;
                            font-weight: 600;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(30px,3px);
                                background-color: $colorMain;
                                position: absolute;
                                bottom: -2px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }

            @include setMaxRwd{
                display: flex;
                flex-direction: column;
                .swiper-act-container{
                    order: 1;
                    margin-top: 1.1em;
                }
            }
        }
    }

    // Grid內容
    &Grid{
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;
        &-list{
            &-flAuto{
                grid-template-columns: repeat(1, auto) 1fr
            }

            &-frAuto{
                grid-template-columns: 1fr repeat(1, auto)
            }
            
            &-1to1{
                grid-template-columns: 1fr 1fr;
            }

            &-2to1{
                grid-template-columns: 2fr 1fr 1fr;
            }
            
            &-avg{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-1to2{
                grid-template-columns: 1fr 1fr 2fr;
            }
        }

        @include pad1080{
            grid-template-columns: auto !important;
        }
    }

    &Flex{
        @extend %setFlex;
    }
}
/* swiper : 首頁 */
.fancy-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-family: $font-family_1;
	--fancy-slider-title-font-size: 88px;
	.swiper {
		width: 100%;
		height: 100%;
		transition-duration: var(--fancy-slider-transition-background-duration);
		transition-property: background-color;
		transition-delay: var(--fancy-slider-transition-background-delay);
	}
	.swiper-wrapper {
		transition-delay: var(--fancy-slider-transition-slide-delay);
		transition-timing-function: var(--fancy-slider-transition-timing-function);
	}
	.swiper-slide {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}

    &Swiper{
        &-sec04{
            align-items: flex-end !important;
            justify-content: flex-end !important;
            .fancy-slider-title-head {
                @include setMinRwd{
                    top: get-vw(-110px);
                    left: get-vw(-240px);
                }
            }
        }
    } 
}

.fancy-slider-no-transition {
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
	transition-property: none !important;
	* {
		&:not(.swiper-wrapper) {
			transition-duration: 0ms !important;
			transition-delay: 0ms !important;
			transition-property: none !important;
		}
	}
	.swiper-wrapper {
		transition-delay: 0ms !important;
	}
}

.fancy-slider-title {
	transition-delay: var(--fancy-slider-transition-title-delay);
	z-index: 10;
    &-head{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: get-vw(40px);
        margin-bottom: get-vw(20px);
        transition-delay: var(--fancy-slider-transition-title-delay);

        @include setMinRwd{
            left: get-vw(190px);
        }
    }

    &-text {
        font-weight: 900;
        font-size: get-vw-mobile(35px);
        line-height: var(--fancy-slider-title-line-height);
        font-family: var(--fancy-slider-title-font-family);
        letter-spacing: -1px;
        color: $color_1;
        -webkit-text-stroke: 2px #fff;

        @include setMinRwd{
            font-size: get-vw(75px);
        }

        @include setMaxRwd{
            color: rgba(255,255,255,1) !important;
        }
    }

    &-subtext{
        letter-spacing: 3px;
        font-size: get-vw-mobile(16px);
        margin-top: 10px;
        color: $color_1;
        -webkit-text-stroke: 2px #fff;
        @include setMinRwd{
            font-size: get-vw(22px);
        }
    }
}

.fancy-slider-scale {
	width: 100%;
	height: 100%;
	position: absolute;
	transform: scale(var(--fancy-slider-transform-scale-slide));
	transition-property: transform;
	transition-timing-function: var(--fancy-slider-transition-timing-function);
	opacity: 0.9;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: scale(var(--fancy-slider-transform-scale-img));
		transition-timing-function: var(--fancy-slider-transition-timing-function);
		transition-property: transform;
	}

    @include mobile{
        img{
            height: 200px;
        }
    }
}

.fancy-slider-button {
	transition: var(--fancy-slider-transition-button);
	outline: none;
	position: absolute;
	width: 140px;
	z-index: 10;
	// top: 50%;
	// transform: translateY(-50%);
    top: 70%;
	cursor: pointer;

    @include setMaxRwd{
        width: 100px;
    }

	svg {
		display: block;
		transition: var(--fancy-slider-transition-button);
	}
	.fancy-slider-svg-circle-wrap {
		transition: var(--fancy-slider-transition-button-circle);
		transform-origin: -20px 40px;
		opacity: 1;
	}
	circle {
		transition: var(--fancy-slider-transition-button-circle);
		stroke-width: 2px;
		stroke: $colorMain;
		fill: none;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		opacity: 1;
		transform-origin: 0px 0px 0px;
	}
	.fancy-slider-svg-arrow {
		transition: var(--fancy-slider-transition-button-arrow);
		fill: $colorMain;
        transform: rotateY(180deg) translate(-49px, 31.1px) scale(3)
	}
	.fancy-slider-svg-wrap {
		transform: translateY(353px);
	}
	.fancy-slider-svg-line {
		transition: var(--fancy-slider-transition-button);
		stroke: $colorMain;
		stroke-width: 2;
		transform: translate(50px, 42px);
	}
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			circle {
				stroke-dasharray: 4px;
				stroke-dashoffset: 4px;
				opacity: 1;
			}
		}
	}
}

.fancy-slider-button-prev {
	left: var(--fancy-slider-button-side-offset);
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			svg {
				transform: translate(-16px);
			}
		}
	}
}

.fancy-slider-button-next {
	right: var(--fancy-slider-button-side-offset);
	.fancy-slider-svg-wrap {
		transform: translateY(353px) rotateY(180deg);
		transform-origin: 80px 0px 0px;
	}
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			svg {
				transform: translate(16px);
			}
		}
	}
}

.fancy-slider-button-disabled {
	opacity: 0.2;
	cursor: default;
}

.fancy-slider{
    .swiper {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
    }

    .swiper-vertical {
        >.swiper-wrapper {
            flex-direction: column;
        }
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        transform: translate(0);
    }

    .swiper-android {
        .swiper-slide {
            transform: translate(0);
        }
    }

    .swiper-pointer-events {
        touch-action: pan-y;
    }

    .swiper-pointer-events.swiper-vertical {
        touch-action: pan-x;
    }

    .swiper-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
    }

    .swiper-slide-invisible-blank {
        visibility: hidden;
    }

    .swiper-autoheight {
        height: auto;
        .swiper-slide {
            height: auto;
        }
        .swiper-wrapper {
            align-items: flex-start;
            transition-property: transform, height;
        }
    }

    .swiper-backface-hidden {
        .swiper-slide {
            transform: translateZ(0);
            backface-visibility: hidden;
        }
    }

    .swiper-3d {
        perspective: 1200px;
        .swiper-wrapper {
            transform-style: preserve-3d;
        }
        .swiper-slide {
            transform-style: preserve-3d;
        }
        .swiper-slide-shadow {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background: rgba(0, 0, 0, 0.15);
        }
        .swiper-slide-shadow-left {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-right {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-top {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-bottom {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-cube-shadow {
            transform-style: preserve-3d;
        }
    }

    .swiper-3d.swiper-css-mode {
        .swiper-wrapper {
            perspective: 1200px;
        }
    }

    .swiper-css-mode {
        >.swiper-wrapper {
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
            >.swiper-slide {
                scroll-snap-align: start start;
            }
        }
    }

    .swiper-horizontal.swiper-css-mode {
        >.swiper-wrapper {
            scroll-snap-type: x mandatory;
        }
    }

    .swiper-vertical.swiper-css-mode {
        >.swiper-wrapper {
            scroll-snap-type: y mandatory;
        }
    }

    .swiper-centered {
        >.swiper-wrapper {
            &:before {
                content: "";
                flex-shrink: 0;
                order: 9999;
            }
            >.swiper-slide {
                scroll-snap-align: center center;
            }
        }
    }

    .swiper-centered.swiper-horizontal {
        >.swiper-wrapper {
            >.swiper-slide {
                &:first-child {
                    margin-inline-start: var(--swiper-centered-offset-before);
                }
            }
            &:before {
                height: 100%;
                width: var(--swiper-centered-offset-after);
            }
        }
    }

    .swiper-centered.swiper-vertical {
        >.swiper-wrapper {
            >.swiper-slide {
                &:first-child {
                    margin-block-start: var(--swiper-centered-offset-before);
                }
            }
            &:before {
                width: 100%;
                height: var(--swiper-centered-offset-after);
            }
        }
    }

    @media (max-width: 480px) {
        .fancy-slider {
            --fancy-slider-title-font-size: 60px;
        }
    }

    @media (max-width: 640px) {
        .fancy-slider-button-prev {
            left: var(--fancy-slider-button-mobile-side-offset);
        }
        .fancy-slider-button-next {
            right: var(--fancy-slider-button-mobile-side-offset);
        }
    }
}
// End

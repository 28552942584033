// 聯絡我們
.contact{
    &-bg{
        @include setBackSet(top left,auto);
        background-image: url('../images/contact_bgInfo.png');
        @include setMinRwd{
            padding-bottom: 120px;
        }

        @include setWidthRwd(1251px,1450px){
            padding-bottom: 140px;
        }

        @include pad1080{
            background-size: 100%;
            padding-bottom: 0;
        }
    }

    &-wrapper{
        position: relative;
        @extend %setFlex;
        padding-top:1em;
        padding-bottom:1.5em;

        .btn-wrap{
            flex-wrap: wrap;
            margin-top: 0.5em;
            margin-bottom: 0.5em;

            .btn{
                @include fontSize(18px);
                border-radius: 0.25em !important;
            }

            @include setMinRwd{
                justify-content: space-between;
                
                .btn{
                    padding: 0.7rem 7rem;
                }
                
                li{
                    flex: none;
                    margin-left: 0 !important;
                    &.contact-btn-option{
                        flex: 1;
                        .btn-wrap {
                            justify-content: flex-end;
                        }
                    }
                }
            }

            @include setMaxRwd{
                li{
                    text-align: center;
                    margin: 0  5px !important;
                }

                .btn{
                    width: 100%;
                }
            }
        }

        @include setMinRwd{
            .radio_style {
                margin-top: 0 !important;
            }
        }

        @include setMaxRwd{
            textarea{
                min-height: 10em
            }

            .contact-map{
                order: -1;
            }
        }
    }
    
    // 基本資訊使用

    &-info{
        overflow: hidden;
        margin-top: 2em;
        margin-bottom: 2em;
        
        &-img{

        }

        &-group{
            dl{
                @extend %setFlex;
                dt{
                    width: 140px;
                    @extend .fw-400;
                    margin-bottom: 15px;
                }
                dd{
                    width: calc(100% - 140px);
                    margin-bottom: 15px;
                }
            }

            @include setMinRwd{
                dl{
                    align-items: center;
                }
            }
        }

        @include setMaxRwd{
            margin-top: 0;
            margin-bottom: 0.5em;
            &-img{
                margin-bottom: 1em;
            }
        }
    }

    // 表單使用
    &-form {
        width: 100%;
        padding: 1rem;

        &-legend{
            padding-bottom: 1em;
            legend{
                float: none;
                font-size: 18px;
            }

            @include setMinRwd{
                padding:0 1em;
            }
        }

        .form-group{
            border:#dcd9d9 1px solid;
            padding: 10px 20px;
            margin-bottom: 20px;
            @include setMinRwd{
                display: flex;
                align-items: start;
                &>label{
                    flex: none;
                    width: 130px;
                    color: #5d5d5e;
                    border-right: #dcd9d9 1px solid;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }

        .form-control{
            border: none;
            @include radius(0);
            @include setMaxRwd{
                background-color: #f5f5f5;
                margin-top: 0.35em;
            }
        }

        &-flex{
            &,
            &-list{
                @extend %setFlex;
                @include setMinRwd{
                    align-items: center;
                }
            }
        }
    }

    // 置放按鈕的
    &-btn{
        &-wrap{
            justify-content: space-between;
            padding-top: 0;
        }
    }
}



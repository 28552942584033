.accordion{
    &-card{
        .btn{
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0;
            font-size: 1rem;
            color: #000 !important;
            background-color: #fff !important;
            text-align: left;
            border: 0;
            border-radius: 0;
            overflow-anchor: none;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
            &::after {
                content: "";
                flex-shrink: 0;
                width: 1.25rem;
                height: 1.25rem;
                margin-left: auto;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-size: 1.25rem;
                transition: transform .2s ease-in-out;
            }
        }
    
        &-flex{
            @extend %setFlex-center;
            padding: 10px;
            
            --min-size: 18;
            --max-size: 18;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            @extend .fw-400
        }
    
        // 
        &-num,
        &-title{
            padding-right: 50px;
        }
        
        @include setMaxRwd{
            margin-top: 1.5em;

            &-flex{ 
                align-items: flex-start;
                padding: 0;
            }

            &-num{
                padding-right: 1em;
            }
            
            &-title{
                padding-top: 2px;
            }

            &-name{
                width: 100%;
                padding-top: 0.75em;
            }

            &-des{
                width: calc(100% - 2em);
            }
        }
    }

    // 原來樣式
    &-button{
        background-color: #fff !important;
        border-radius: 0 !important;
        border-top: 1px solid #d2d2d2;
        &:not(.collapsed){
            color: #000;
            border-top-color: #000;
            &::after{
                background-image: var(--bs-accordion-btn-icon);
            }
        }
    }

    &-body{
        background-color: #f6f3f2;
    }

    &-item{
        border:none;
        &:last-child{
            .accordion-button{
                border-bottom: 1px solid #d2d2d2;
            }
        }
    }
}

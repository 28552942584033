.news{

    &-list{
        // 列表
        &-group{
            .list-group-item{
                background-color: transparent;
                display: flex;
                border-width: 1px 0 0 0;
                border-radius: 0;
                border-color: #000;
                padding-top: 0.75em;
                padding-bottom: 0.75em;
    
                .time,
                .txt{
                    --min-size: 18;
                    --max-size: 20;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                }

                .time{
                    line-height: 1.25;
                }

                .txt{
                    // 顯示年月日
                    &-time{
                        --min-size: 20;
                        --max-size: 30;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                        font-family: $Fira, Arial;
                        margin-bottom: 5px;
                        @include setMinRwd{
                            margin-bottom: 1.75rem;
                        }
                    }
                }
        
                &:last-child{
                    border-bottom-width: 1px
                }
        
                &:hover{
                    background-color: #f6f3f2;
                }

                @include setMinRwd{
                    padding-top: 2em;
                    padding-bottom: 2em;
                }
            }
        }

        // 列表圖
        &-img{}
    }

    // 列表框


    // 詳細頁
    &-detail-wrap{
        .list-group-item{
            &:hover{
                background-color: transparent;
            }

            @include setMaxRwd{
                padding-top: 1em;
                padding-bottom: 1em;
            }
        }
    }
}
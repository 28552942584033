@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("//fonts.googleapis.com/css2?family=Fira+Sans&display=swap");
@import url("//fonts.googleapis.com/css2?family=Allura&display=swap");
.is-wrapper *, a, body {
  color: #282727;
  font-size: 16px;
  font-family: "Noto Sans TC", "微軟正黑體", "Microsoft JhengHei", "Arial", sans-serif;
  line-height: 1.65;
  font-weight: 400;
}

input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-form-flex, .contact-form-flex-list, .contact-info-group dl, .contact-wrapper, .Prod-conSpec, .Prod-headingTitle, .pageFlex, .page-gallerSwiper-pro .swiper-act-container, .index-pageWrapper, .card-wrap, .box, .pagination-wrap, .mainContent, .cookies-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.counter, .accordion-card-flex, .page-gallerSwiper-pro .swiper-act-button, .member-editor-buildCase-tab .btn-wrap .btn, .member-editor-buildCase-tab, .card-time, .btn-wrap, .checkbox_style, .radio_style, .title-mainWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pro-card-wrap .card-img-top img, .imgCenter-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.list-grid-1 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-1 {
    grid-template-columns: repeat(1, auto);
  }
}

.list-grid-2 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-2 {
    grid-template-columns: repeat(2, auto);
  }
}

.list-grid-3 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-3 {
    grid-template-columns: repeat(3, auto);
  }
}

.list-grid-4 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-4 {
    grid-template-columns: repeat(4, auto);
  }
}

.list-grid-5 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-5 {
    grid-template-columns: repeat(5, auto);
  }
}

.list-grid-6 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-6 {
    grid-template-columns: repeat(6, auto);
  }
}

.list-grid-7 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-7 {
    grid-template-columns: repeat(7, auto);
  }
}

.list-grid-8 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-8 {
    grid-template-columns: repeat(8, auto);
  }
}

.list-grid-9 {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .list-grid-9 {
    grid-template-columns: repeat(9, auto);
  }
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1200px) {
  .hide {
    display: block !important;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.wrapper-750 {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.wrapper-700 {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.wrapper-650 {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.wrapper-600 {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.wrapper-550 {
  width: 100%;
  max-width: 550px;
  margin: auto;
}

.wrapper-500 {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.is-wrapper * {
  letter-spacing: 0px !important;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  position: fixed;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  transition: all 0.3s ease-out;
}
@media (min-width: 1201px) {
  .start-header.scroll-on .navbar-brand img {
    transition: all 0.3s ease-out;
  }
  .start-header.scroll-on .search-formList {
    top: 105px;
  }
}
.start-header .container {
  max-width: 1600px;
}
.start-header .container img {
  margin: 0 !important;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}
@media (max-width: 1200px) {
  .navigation-wrap {
    padding: 5px 0;
    z-index: 1052;
  }
  .navigation-wrap.bg-light {
    background-color: transparent !important;
  }
}

.navbar {
  font-size: 16px;
  font-size: 1rem;
  padding: 1em 0.75em;
}
@media (max-width: 1080px) {
  .navbar {
    padding: 0;
    justify-content: space-between;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  display: block;
  transition: all 0.3s ease-out;
}
@media (max-width: 1080px) {
  .navbar-brand {
    max-width: 15.625vw;
  }
}
.navbar-light .navbar-toggler-icon {
  width: 25px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 25px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
}
.navbar-collapse {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1201px) {
  .navbar-collapse {
    flex: auto;
    flex-wrap: nowrap;
    align-items: flex-end;
  }
}
@media (min-width: 800px) and (max-width: 1500px) {
  .navbar-collapse {
    width: 100%;
    margin-top: 0;
    flex-wrap: nowrap;
  }
}
@media (min-width: 1070px) and (max-width: 1300px) {
  .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    max-width: 60vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 100;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
  }
  .navbar-collapse.show {
    display: block !important;
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1201px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
@media (max-width: 1080px) {
  .navbar-toggler {
    display: flex !important;
    align-items: center;
    flex-direction: column;
  }
  .navbar-toggler .icon-txt {
    margin-top: 10px;
  }
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none !important;
  position: fixed;
  left: 59%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block !important;
}
.navbar-nav.link {
  justify-content: space-between;
}
@media (min-width: 1201px) {
  .navbar-nav.link .nav-item {
    text-align: center;
  }
  .navbar-nav.link .nav-item .nav-link {
    --min-size: 16;
    --max-size: 20;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    padding: 0 15px !important;
    margin: 0 15px;
    line-height: 2;
    display: block;
  }
  .navbar-nav.link .nav-item .nav-link::before {
    content: "\f282";
    font-family: bootstrap-icons !important;
    position: absolute;
    right: -9px;
    top: 3px;
    font-size: 0.85em;
  }
  .navbar-nav.link .nav-item .nav-link::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    max-width: 65px;
    max-height: 11px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    background-image: url("../images/navbar-link-hover.png");
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-nav.link .nav-item .nav-link::before, .navbar-nav.link .nav-item .nav-link::after {
    transition: 0.5s;
    opacity: 0;
  }
}
.navbar-nav.link .nav-item:hover .nav-link, .navbar-nav.link .nav-item.active .nav-link {
  color: #5f4437;
}
.navbar-nav.link .nav-item:hover .nav-link:before, .navbar-nav.link .nav-item:hover .nav-link::after, .navbar-nav.link .nav-item.active .nav-link:before, .navbar-nav.link .nav-item.active .nav-link::after {
  opacity: 1;
}
.navbar-nav.link .dropdown-menu {
  box-shadow: none;
  background: transparent;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item {
  font-size: 16px;
  font-size: 1rem;
  background-color: transparent !important;
  color: #000 !important;
  line-height: 1.05;
  letter-spacing: 0px;
}
@media (min-width: 1201px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-item {
    white-space: normal;
    padding: 10px 6px;
    text-align: center;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-item:hover {
    background-color: #5f4437 !important;
    color: white !important;
  }
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
  color: #666;
  padding-left: 1.25rem;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background-color: #696969;
  position: absolute;
  top: 12.5px;
  left: 0;
}
@media (max-width: 1200px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
    padding-top: 0;
    margin-top: -2px;
    padding-left: 20px;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    position: static;
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
    font-size: 15px;
    font-size: 0.9375rem;
    background-color: transparent !important;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item:last-child {
    border-bottom: none;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1201px) {
  .navbar-nav.link {
    position: relative;
    margin-left: auto;
    padding-right: 1em;
  }
  .navbar-nav.link .nav-link {
    text-transform: uppercase;
    flex-direction: column;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    left: 50%;
    padding: 0 !important;
    transform: translate3d(-50%, 0px, 0);
    min-width: 12em;
    max-height: 100vw;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list {
    width: 100%;
    padding: 10px;
    background: #e8e4ed;
    max-height: 100vw;
    border-radius: 5px;
    position: relative;
    top: 5px;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list::before {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-nav.link .dropdown-item:hover {
    background-color: transparent;
    color: #000;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.link {
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1201px) {
  .navbar-nav.info {
    flex-direction: column;
    align-items: center;
  }
}
.navbar-nav.info .input-group {
  border-bottom: #b5b5b5 1px solid;
  padding-bottom: 4px;
}
.navbar-nav.info .input-group .form-control {
  border-color: transparent;
  background-color: #fff;
  max-width: 10em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 14px;
}
.navbar-nav.info .input-group .btn {
  font-size: 14px;
  padding: 0.1em 0.5em !important;
  margin-top: 1px;
  height: 2em;
}
.navbar-nav.info .input-group .btn, .navbar-nav.info .input-group .btn:hover {
  border-color: transparent !important;
  background-color: transparent !important;
}
.navbar-nav.info .input-group .btn .icon {
  margin-top: -2px;
}
@media (max-width: 1080px) {
  .navbar-nav.info {
    flex-wrap: wrap;
  }
  .navbar-nav.info > li {
    flex: 1;
  }
  .navbar-nav.info > li.nav-item-social {
    justify-content: space-between;
    order: 1;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 0.5rem;
  }
  .navbar-nav.info > li.nav-item-social::before {
    display: none;
  }
  .navbar-nav.info > li.nav-item-search {
    display: none;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 40px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .bi {
  transform: rotate(180deg);
}

.nav-link {
  position: relative;
  display: inline-block;
  color: #282727 !important;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 500;
  padding: 5px 0 !important;
  transition: all 200ms linear;
}
@media (min-width: 1201px) {
  .nav-link {
    line-height: 3;
  }
}
@media (max-width: 1080px) {
  .nav-link {
    padding: 10px 0 !important;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #5f4437 !important;
}
.nav-item .icon {
  display: inline-block;
  vertical-align: top;
  flex: none;
  margin-top: -2px;
}
.nav-item .icon .bi-caret-down-fill {
  font-size: 12px;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 1.5em;
}
.nav-item img {
  margin: 0 !important;
}
@media (min-width: 1701px) and (max-width: 1800px) {
  .nav-item {
    margin-left: 1.35em !important;
  }
}
@media (min-width: 1201px) and (max-width: 1700px) {
  .nav-item {
    margin-left: 1.25em !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .nav-item {
    margin-left: 0.5em !important;
  }
}
@media (max-width: 1200px) {
  .nav-item .dropdown-menu-list li {
    position: relative;
    margin-bottom: 0.25em;
  }
  .nav-item .dropdown-menu-list li .dropdown-item {
    padding-left: 0;
  }
  .nav-item .dropdown-menu-list li .navbar-mobile-icon {
    right: -1.3em;
    top: -4px;
  }
}
@media (max-width: 1080px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 24px;
    left: 5px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  font-size: 14px;
  line-height: 1.5;
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #5f4437;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  min-width: 6.75em;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item .dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 1201px) {
  .nav-item .dropdown-menu {
    border-radius: 5px;
  }
}
@media (max-width: 1200px) {
  .nav-item .dropdown-menu {
    padding-top: 0 !important;
  }
  .nav-item .dropdown-menu .dropdown-menu-list {
    padding-left: 10px;
  }
  .nav-item .dropdown-menu .dropdown-menu-list li {
    margin-bottom: 0.5em;
  }
}
.nav-item-social {
  position: relative !important;
  width: 100%;
  display: inline-flex;
  padding-bottom: 15px;
}
@media (min-width: 1201px) {
  .nav-item-social {
    justify-content: flex-end;
  }
}
.nav-item-cart {
  position: relative;
}
.nav-item-cart::before {
  content: attr(data-totalitems);
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: -10px;
  background: #e8883b;
  padding: 0 5px;
  min-width: 24px;
  height: 24px;
  line-height: 20px;
  color: white;
  text-align: center;
  border-radius: 24px;
}

.icon-txt-cart::before {
  content: "(" attr(data-totalitems) ")";
  font-size: 13px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  font-family: "Fira Sans", sans-serif, Arial;
}

@media (max-width: 1200px) {
  .nabar-icon-mobile {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5em;
  }
  .nabar-icon-mobile .icon-svg svg, .nabar-icon-mobile .icon-svg image {
    width: 1.75em;
    height: 1.75em;
  }
  .nabar-icon-mobile .icon-txt {
    --min-size: 12;
    --max-size: 34;
    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    padding-top: 5px;
    color: var(--bs-navbar-color);
  }
  .nabar-icon-mobile .mobile-navbaricon {
    margin-left: 1em;
  }
}

.mobile-navbaricon,
.toggle-search {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-formList {
  position: absolute;
  top: 100px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  transition: 0.5s;
  display: none;
}
.search-formList.open {
  display: block;
}
@media (min-width: 1101px) and (max-width: 1200px) {
  .search-formList {
    top: 5.25em;
  }
}
@media (max-width: 1200px) {
  .search-formList {
    top: 5.25em;
  }
}
.search-formInput {
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  background-color: #e1e4e7;
  max-width: 40em;
}
.search-formInput fieldset {
  position: relative;
  margin: 0 60px 0 0;
  padding: 0;
  border: none;
}
.search-formInput input {
  display: block;
  height: 60px;
}
.search-formInput input[type=search] {
  width: 100%;
  padding: 0 5px 0 22px;
  background: transparent;
  border: none;
}
.search-formInput input[type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: white;
  background: #5f4437;
  border: none;
  cursor: pointer;
}

footer {
  position: relative;
  z-index: 2;
  padding: 1em;
  background-color: #3e2a12;
}
footer, footer a {
  color: #fff;
}
footer .tag-item, footer .tag-item-current, footer .tag-item-date {
  font-size: 15px;
  background-color: #161616;
  color: #bfbfbf;
  padding: 5px 5px 6px;
}
@media (min-width: 1201px) {
  footer .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
footer .footer-info p {
  margin-bottom: 10px;
}
footer .footer-info .list-group-item {
  background-color: transparent;
  color: #d0c5b6;
  padding-left: 0;
  padding-bottom: 0;
  border: none;
}
footer .footer-info .copyright {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #adabab;
}
footer .footer-info-logo {
  padding-bottom: 0.65em;
}
footer .footer-info-logo ~ p {
  padding-left: 55px;
}
footer .footer-info-logo ~ p span.text-muted {
  position: absolute;
  left: 0;
  color: #fff !important;
}
footer .footer-info-left, footer .footer-info-left-row {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1201px) {
  footer .footer-info-left-item {
    flex: none;
    max-width: 20em;
  }
}
footer .footer-info-left-row {
  padding-top: 50px;
}
@media (min-width: 1201px) {
  footer .footer-info-left-row {
    justify-content: center;
  }
  footer .footer-info-left-row li {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 1200px) {
  footer .footer-info-left {
    flex-direction: column;
  }
  footer .footer-info-left-row {
    padding-top: 1.5em;
  }
  footer .footer-info-left-row li {
    flex: 0 0 50%;
    margin-bottom: 1em;
  }
}
footer .footer-info-right {
  padding-top: 50px;
}
footer .footer-info-right .iware {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
}
@media (min-width: 1201px) {
  footer .footer-info-right {
    text-align: end;
    padding-bottom: 5px;
  }
}
@media (max-width: 1200px) {
  footer .footer-info-right {
    padding-top: 1.5em;
  }
  footer .footer-info-right p {
    font-size: 13px !important;
  }
}
@media (min-width: 1201px) {
  footer {
    padding-top: 60px;
    padding-bottom: 90px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  footer {
    padding-left: 3em;
    padding-right: 4em;
  }
}
@media (max-width: 1200px) {
  footer {
    padding-bottom: 5em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  display: inline-block;
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
}
.iware, .iware a {
  color: #bfbfbf;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1201px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1200px) {
  .float-link .link-btn.top {
    display: none;
  }
}
@media (min-width: 1201px) {
  .float-link .link-btn-social {
    margin-bottom: 0.5em;
  }
}
@media (max-width: 1200px) {
  .float-link .link-btn-social svg, .float-link .link-btn-social image {
    width: 3em;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .float-link .link-btn {
    margin-bottom: -0.25rem;
  }
  .float-link .link-btn img, .float-link .link-btn svg, .float-link .link-btn image {
    width: 3em;
  }
}
.float-link.active {
  right: 15px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1200px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #5f4437;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon {
    margin-left: 0;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
    padding-right: 5px;
  }
}

.cookies {
  display: none;
  width: 100%;
  background: #303030;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
}
.cookies p {
  font-weight: 400;
  text-align: center;
  line-height: 2;
}
@media (min-width: 1201px) {
  .cookies p {
    text-align: left;
    margin-bottom: 0;
  }
}
.cookies .btn {
  text-decoration: none !important;
  border-radius: 10px !important;
}
@media (min-width: 800px) {
  .cookies .btn {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 640px) {
  .cookies .btn {
    display: block;
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
  }
}
.cookies-wrapper {
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1201px) {
  .cookies-wrapper {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
  }
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
.cookies.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}
@media (max-width: 810px) {
  .cookies.close-cookies {
    bottom: -2%;
  }
}
@media (min-width: 1201px) {
  .cookies .btn {
    font-size: 18px;
    font-size: 1.125rem;
    margin-left: 2.5em;
    padding: 0.5rem 2.5rem;
  }
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    border-top: none;
    margin: 0.5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookies.display {
    flex-direction: column;
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.in-content {
  padding-top: 2.6315789474vw;
  padding-bottom: 2.6315789474vw;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .in-content {
    padding-left: 3rem;
    padding-right: 4rem;
  }
}
@media (max-width: 1200px) {
  .in-content {
    padding-top: 3.90625vw;
    padding-bottom: 3.90625vw;
  }
}

.imgCenter-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 59.5%;
  overflow: hidden;
}
.imgCenter-4-3 img {
  max-height: none;
}

.g-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1200px) {
  .g-recaptcha {
    margin-bottom: 1rem;
  }
}

.fw-400, .contact-info-group dl dt, .Prod-unreset .nav-link, .accordion-card-flex, .policy-section .title {
  font-weight: 400 !important;
}
.fw-500, .pro-card-wrap .card-title {
  font-weight: 500 !important;
}
.fw-600, .price-cur, .searchResult-wrapper .searchResult-info-headTitle {
  font-weight: 600 !important;
}
.fw-900, .page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after, .card-btn-more, .card-time-date, .heading-title-article-slogan, .title-ch, .title-en {
  font-weight: 900 !important;
}

.mainContent {
  overflow: hidden;
  padding-top: 1.2em;
}
.mainContent-wrap {
  padding-bottom: 2em;
  background-color: #f8f8f8;
}
@media (max-width: 1200px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1201px) {
  .mainContent-info {
    width: 240px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 240px);
    padding-left: 155px;
    padding-top: 1.65em;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .mainContent-info ~ .mainContent-list {
    padding-left: 50px;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
}
@media (max-width: 1200px) {
  .mainContent-list {
    padding: 0.5em 0.1em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.link-wrap {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px 10px 20px 0;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #6f6f6f;
  padding: 0.75em 50px 0.75rem 35px;
  background-color: #f6f3f2;
  margin-bottom: 1px;
}
.link-wrap ul > li > a::after {
  content: "\f231";
  font-family: "bootstrap-icons";
  display: block;
  color: #e8883b;
  position: absolute;
  top: 50%;
  left: 0.75em;
  margin-top: -11px;
  font-weight: 100;
  font-size: 1em;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  color: #282727;
}
.link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active::after {
  opacity: 1;
}
@media (min-width: 1201px) {
  .link-wrap ul > li > a::after {
    opacity: 0;
    left: 30px;
  }
  .link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
    padding-left: 3em;
  }
}
@media (min-width: 1020px) and (max-width: 1500px) {
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    padding-bottom: 0;
    margin-bottom: 1rem;
  }
}
.link-head {
  font-size: 25px;
  font-size: 1.5625rem;
  color: #000;
  padding-left: 1.2rem;
  margin-bottom: 0;
}
.link-head-en {
  font-size: 16px;
  padding-top: 0.15em;
}
.link-head-wrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5em;
  padding-left: 1.25em;
}
@media (max-width: 1200px) {
  .link-head-wrap {
    display: none;
  }
}
:root {
  --linkCur:#9e816d ;
}

.link-horizontal-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
}
.link-horizontal-wrap .list-group {
  flex-wrap: wrap;
  overflow: hidden;
}
.link-horizontal-wrap .list-group, .link-horizontal-wrap .list-group-item {
  border-radius: 0 !important;
}
.link-horizontal-wrap .list-group-item {
  position: relative;
  border: none;
  padding: 0;
}
.link-horizontal-wrap .list-group-item a {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  display: block;
  text-align: center;
  padding: 0.35em 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border: #5f4437 1px solid;
}
.link-horizontal-wrap .list-group-item.cur a {
  color: #fff;
  background-color: var(--linkCur);
  border-color: var(--linkCur);
}
@media (min-width: 1201px) {
  .link-horizontal-wrap .list-group-item {
    flex: none;
    min-width: 10em;
    max-width: 100%;
  }
  .link-horizontal-wrap .list-group-item:hover a {
    color: #fff;
    background-color: var(--linkCur);
    border-color: var(--linkCur);
  }
}
@media (max-width: 1200px) {
  .link-horizontal-wrap .list-group {
    width: 100%;
    flex-wrap: wrap;
  }
  .link-horizontal-wrap .list-group-item {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0 !important;
    margin-bottom: 1px;
  }
}
@media (max-width: 1200px) {
  .link-horizontal-wrap {
    display: none;
    margin-bottom: 0;
  }
  .link-horizontal-wrap.open {
    display: flex;
  }
}

.btn.list-group-mobile-btn {
  color: #fff !important;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 3em !important;
}

.boxShadow, .member-content .form-box {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1200px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  background-color: #fff;
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.title-wrap {
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.title-wrap .btn .icon {
  font-size: 23px;
  font-size: 1.4375rem;
}
@media (max-width: 1200px) {
  .title-wrap {
    margin-bottom: 2px;
  }
}
.title-spacing-2 {
  letter-spacing: 2px;
}
.title-spacing-3 {
  letter-spacing: 3px;
}
.title-spacing-4 {
  letter-spacing: 4px;
}
.title-spacing-5 {
  letter-spacing: 5px;
}
.title-en {
  font-family: "Mitr", sans-serif;
  font-size: 35px;
  font-size: 2.1875rem;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 810px) {
  .title-en {
    font-size: 6.25vw;
  }
}
.title-ch {
  position: relative;
  font-size: 45px;
  font-size: 2.8125rem;
  color: #595757;
}
@media (max-width: 810px) {
  .title-ch {
    font-size: 4.375vw;
  }
}
@media (max-width: 640px) {
  .title-ch {
    font-size: 7.5vw;
  }
}
.title-mainWrap {
  justify-content: space-between;
  padding-top: 0.25em;
}
@media (max-width: 1200px) {
  .title-mainWrap {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}
.titleStyle {
  position: relative;
  padding: 0.25em;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #d0c5b6;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: 8px;
}
.title__topLine {
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #000;
}
.title__solid {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #b3b3b3;
}
.title__underLine {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.title-left-circle {
  position: relative;
  padding-left: 30px;
  z-index: 1;
}
.title-left-circle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: #5f4437 2px solid;
  border-radius: 50%;
  top: 11px;
  left: 10px;
  z-index: -1;
}
.title-right-squre {
  position: relative;
  padding-left: 15px;
  z-index: 1;
}
.title-right-squre::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #0125fd;
  position: absolute;
  bottom: 2px;
  right: -40px;
  z-index: -1;
}
.title__topHead::before {
  content: "";
  display: block;
  width: 30px;
  height: 0.5rem;
  background-color: #5f4437;
  margin-bottom: 1rem;
}
.title__bottomHead::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.2rem;
  background-color: #e8883b;
  position: absolute;
  bottom: 0;
  left: 0;
}

.heading-title {
  text-align: left;
}
.heading-title-txt {
  --min-size: 22;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-family: "ar-mingb5std", serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 2px;
}
.heading-title-txt, .heading-title-txt-en {
  position: relative;
  padding-right: 0.5rem;
}
.heading-title-txt-en {
  --min-size: 13;
  --max-size: 14;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #9e9f9e;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 0.25em;
  font-family: Helvetica;
}
.heading-title-article {
  border: #5f4437 1px solid;
  border-top: none;
  padding: 0 2em 1em;
}
.heading-title-article::before, .heading-title-article::after {
  content: "";
  display: block;
  width: 1.3em;
  height: 1px;
  background-color: #5f4437;
  position: absolute;
  top: 0;
}
.heading-title-article::before {
  left: 0;
}
.heading-title-article::after {
  right: 0;
}
.heading-title-article-slogan {
  --min-size: 24;
  --max-size: 48;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5f4437;
  position: relative;
  top: -0.65em;
  margin-bottom: -0.65em;
  line-height: 1.1;
  text-transform: uppercase;
}
.heading-title-article-txt {
  --min-size: 18;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0.3em;
  color: #644689;
}
@media (min-width: 1201px) {
  .heading-title {
    margin-bottom: 1em;
  }
}

.mainheading-title {
  margin-top: 0.25em;
  margin-bottom: 1em;
}
.mainheading-title-name {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-bottom: 0.875em;
}
.mainheading-title-name::after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background-color: #bfbfbf;
  position: relative;
  top: 0.6em;
}
.mainheading-title-subname {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #979595;
}

.sample_title_1 {
  position: relative;
  display: inline-block;
  padding-right: 55px;
}
.sample_title_1::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  display: inline-block;
  width: 45px;
  height: 1px;
  background-color: #282727;
}

.breadcrum-wrap {
  margin-bottom: 0.7894736842vw;
}
@media (max-width: 1600px) {
  .breadcrum-wrap {
    padding-right: 2.34375vw;
  }
}
@media (max-width: 1200px) {
  .breadcrum-wrap {
    margin-top: 1.5625vw;
    margin-bottom: 2.34375vw;
  }
}
.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #777;
  text-transform: uppercase;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding-left: 0.15em;
  padding-right: 0.5em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: #5f4437;
}
@media (max-width: 1200px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 0;
    padding-top: 0.3em;
    padding-bottom: 0;
  }
}
.breadcrum-wrap .icon {
  margin-top: -6px;
  margin-right: 2px;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 1rem;
}
.pagination-wrap .pagination {
  justify-content: center;
  align-items: center;
}
.pagination-wrap .pagination .page-link {
  width: 40px;
  height: 50px;
  font-size: 18px;
  color: #6e6d6d;
  font-family: "Roboto";
  line-height: 50px;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0%;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #000;
}
.pagination-wrap .pagination .page-link-arrow {
  width: auto;
  height: auto;
}
.pagination-wrap .pagination .page-link-arrow svg {
  opacity: 0.5;
}
.pagination-wrap .pagination .page-link-arrow:hover svg {
  opacity: 1;
}
.pagination-wrap .pagination-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap .pagination-select select {
  text-align: center;
  appearance: auto;
  font-size: 15px;
  font-size: 0.9375rem;
}
.pagination-wrap .pagination-select .pagination-item {
  flex: none;
  padding-left: 10px;
  padding-right: 10px;
}
.pagination-wrap .pagination-mobile .pagination-select {
  max-width: 16em;
  margin: auto;
}
@media (min-width: 1201px) {
  .pagination-wrap .pagination-mobile .pagination-select {
    width: auto;
  }
  .pagination-wrap .pagination-mobile .pagination-select select {
    max-width: 45px;
  }
}
@media (max-width: 1200px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.text-main {
  color: #5f4437 !important;
}
.text-itemSub {
  color: #7d7d7d !important;
}
.text-underLine {
  text-decoration: underline !important;
}
.text-uppercase, .mainheading-title-name {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.bg-itemSub {
  background-color: #6f6e6e !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.tag-item, .tag-item-current, .tag-item-date {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #5b5a5a;
}
.tag-item-current, .tag-item-date {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
}
.tag-item-current {
  background-color: #00b7ee;
}
.tag-item-date {
  background-color: #bfbf85;
}
.tag-item-wrap .tag-item ~ .tag-item, .tag-item-wrap .tag-item-current ~ .tag-item, .tag-item-wrap .tag-item-date ~ .tag-item, .tag-item-wrap .tag-item ~ .tag-item-current, .tag-item-wrap .tag-item-current ~ .tag-item-current, .tag-item-wrap .tag-item-date ~ .tag-item-current, .tag-item-wrap .tag-item ~ .tag-item-date, .tag-item-wrap .tag-item-current ~ .tag-item-date, .tag-item-wrap .tag-item-date ~ .tag-item-date {
  margin-left: 5px;
}
@media (min-width: 1201px) {
  .tag-item-wrap {
    padding-left: 15px;
  }
}
@media (max-width: 1200px) {
  .tag-item-wrap {
    padding-top: 10px;
  }
}

.detail-label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail-label-other {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}
.detail-blockquote {
  display: block;
  background: #fff;
  padding: 15px 20px 12px 25px;
  margin: 0 0 20px;
  position: relative;
  /*Font*/
  font-size: 16px;
  /*Borders - (Optional)*/
  border-left: 10px solid #5f4437;
  /*Box Shadow - (Optional)*/
  box-shadow: 2px 2px 15px #ccc;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 4px;
}
.radio_style input:checked + label::after {
  background-color: #000;
}

.policy-section p,
.policy-section li {
  color: #333;
  font-size: 1rem;
  line-height: 1.75;
}
.policy-section p {
  margin-bottom: 0;
}
.policy-section ol {
  padding-left: 1em;
}
.policy-section .txt {
  margin-bottom: 0.25rem;
}
.policy-section .title {
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 0.2em;
}
.policy-section .policy-sec {
  margin-bottom: 40px;
}

.mix-darken {
  mix-blend-mode: darken;
}

.checkbox_style > input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 2.75rem;
  cursor: pointer;
  border: none;
}
.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  background-color: #fff;
  border: #010101 1px solid;
  border-radius: 4px;
}
.checkbox_style > input + label::after {
  content: "\f26e";
  font-family: bootstrap-icons !important;
  position: absolute;
  left: 3.5px;
  font-size: 1.5rem;
  color: #fff;
  opacity: 0;
}
.checkbox_style > input:checked + label::before {
  background-color: #5f4437;
  border-color: #5f4437;
}
.checkbox_style > input:checked + label::after {
  opacity: 1;
}
.checkbox_style p {
  margin-top: 10px;
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1201px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn:hover {
  background-color: #5f4437 !important;
  border-color: #5f4437 !important;
  color: #fff !important;
}

.btn:hover {
  background-color: #5f4437 !important;
  border-color: #5f4437 !important;
  color: #fff !important;
}

:root {
  --btnCur:#9e816d ;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 100;
  border-radius: 0 !important;
  padding: 0.35em 1.5em 0.5em;
}
@media (max-width: 1200px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.btn .icon {
  vertical-align: top;
  margin-top: 0px;
  margin-right: 3px;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.btn-main {
  background-color: #5f4437;
  color: #fff;
}
.btn-mainsecondary {
  background-color: #e8883b;
}
.btn-mainthird {
  background-color: #8cbb59;
}
.btn-skin {
  background-color: #dec9bc;
}
.btn-skinCur {
  background-color: var(--btnCur) !important;
}
.btn-white {
  background-color: #fff;
}
.btn-outline-dark {
  border: 1px solid #212529 !important;
}

.dt-btn-wrap .btn {
  display: flex;
  align-items: center;
  color: #000;
}
.dt-btn-wrap .btn, .dt-btn-wrap .btn:hover {
  border-color: transparent !important;
  background-color: transparent !important;
}
.dt-btn-wrap .btn .icon {
  margin-right: 15px;
}
.dt-btn-wrap .btn:hover {
  color: #5f4437 !important;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 2em;
  border: none;
  text-align: left;
  border-radius: 0;
}
@media (min-width: 1201px) {
  .card__2 {
    flex: 0 0 calc(50% - 4em);
    max-width: calc(50% - 4em);
  }
}
@media (min-width: 1201px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 4em);
    max-width: calc(33.3333333333% - 4em);
  }
}
@media (min-width: 1201px) {
  .card__4 {
    flex: 0 0 calc(25% - 4em);
    max-width: calc(25% - 4em);
  }
}
@media (max-width: 1200px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 4em) !important;
  max-width: calc(100% - 4em) !important;
}
@media (min-width: 1201px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time-date {
  font-size: 24px;
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  .card-time-date {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-time-year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (min-width: 1201px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 900;
  line-height: 1.5;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-body {
  padding: 1.25em;
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #5b5a5a;
  font-weight: 100;
  line-height: 1.3;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  background-color: #c9c9c9 !important;
  color: #fff !important;
  margin-bottom: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.card-btn:hover {
  background-color: #5f4437 !important;
}
.card-btn-more {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: #333 1px solid;
  padding-bottom: 2px;
}
@media (max-width: 640px) {
  .card-btn {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-linkNormal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1200px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

@media (min-width: 1201px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1201px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}
.form-box .title {
  text-align: center;
  padding: 20px 0 15px;
  font-size: 30px;
  font-size: 1.875rem;
  color: #5f4437;
}
.form-box .section {
  padding: 20px 0;
}
.form-box .form-control {
  height: 50px;
  border-radius: 0;
}
.form-box .message {
  display: block;
  width: 100%;
  margin: 20px 0 0;
  font-size: 16px;
  text-align: right;
}
.form-box .message a {
  position: relative;
  display: inline-block;
  width: 25%;
  color: #636262;
  text-decoration: none;
  text-align: left;
  border-bottom: #ccc 1px solid;
  padding-bottom: 10px;
}
.form-box .message a::after {
  content: "→";
  display: inline-block;
  float: right;
}
.form-box.forget {
  display: none;
}
.form-box .form-list p {
  margin-bottom: 0;
}
.form-box.regis .form-group {
  display: flex;
  align-items: center;
}
.form-box.regis .form-group > label {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.form-box.regis .form-group .form-list {
  flex: 1;
  display: inline-flex;
  align-items: center;
}
.form-box.regis .form-group .form-list label {
  margin-bottom: 0;
}
.form-box.regis.regis-form .form-group {
  align-items: normal;
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
}
.form-box.regis.regis-form .form-group > label {
  min-width: 200px;
  background: #f0f0f0;
  padding: 10px;
}
.form-box.regis.regis-form .form-group .form-list {
  padding: 5px 10px;
}
.form-box.regis.regis-form .form-group .form-list .form-control {
  margin-bottom: 5px;
}
@media (max-width: 810px) {
  .form-box.regis.regis-form .form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-box.regis.regis-form .form-group > label {
    width: 100%;
    flex-direction: row !important;
    justify-content: flex-start;
    font-size: 16px;
    font-size: 1rem;
  }
  .form-box.regis.regis-form .form-group .form-list {
    width: 100%;
    flex-wrap: wrap;
  }
  .form-box.regis.regis-form .form-group .radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .form-box.regis.regis-form .form-group .btn {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 0 !important;
  }
  .form-box.regis.regis-form .form-group .form-input .code {
    width: inherit;
  }
}
.form-status {
  margin-bottom: 1.5rem;
}
.form-status-link .icon {
  margin-top: -3px;
}

.member-content {
  padding-top: 10px;
  padding-bottom: 40px;
}
.member-content .form-box {
  max-width: 650px;
  background-color: #fff;
  border-radius: 25px;
  padding: 1.5em 3em 1.5em 2em;
  overflow: hidden;
}
.member-content-intro {
  background-color: #eee;
}
.member-content-intro-wrap {
  max-width: 600px;
}
.member-content-login {
  background-color: #fff;
}
.member-content-login .form-group {
  margin-bottom: 1.5rem;
}
.member-content-login .btn-wrap {
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  .member-content-login .btn-wrap {
    flex-wrap: wrap;
  }
  .member-content-login .btn-wrap > li {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 1200px) {
  .member-content {
    padding: 0em;
  }
}
.member-editor .form-box {
  padding: 5px 0;
  border-radius: 0;
}
.member-editor .form-group label {
  width: 150px;
}
.member-editor .form-group > label {
  padding-left: 0;
}
.member-editor .form-group-checkbox {
  flex-direction: row;
  padding-bottom: 0;
  margin-bottom: 0;
}
.member-editor .form-group-checkbox label {
  width: 100% !important;
}
.member-editor .form-group-checkbox .radio_style {
  margin-top: 0px !important;
}
.member-editor .form-group .uploader {
  min-width: 20em;
  max-width: 100%;
  flex: 1;
}
@media (min-width: 1201px) {
  .member-editor .form-group .uploader {
    max-width: 20em;
  }
}
.member-editor .form-group .uploader-btn .btn {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
@media (max-width: 1200px) {
  .member-editor .form-group .uploader-btn {
    margin-top: 1em;
  }
}
.member-editor-buildCase-form {
  background: #eceae8;
  border-radius: 30px;
  border-top-left-radius: 0;
  padding: 2em;
}
.member-editor-buildCase-form .form-box {
  flex-wrap: wrap;
  background-color: transparent;
}
.member-editor-buildCase-form .pagination-wrap {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
@media (max-width: 1200px) {
  .member-editor-buildCase-form {
    border-top-right-radius: 0;
    padding: 0.75em 1.5em;
    margin-top: 0;
  }
}
.member-editor-buildCase-tab {
  justify-content: space-between;
}
.member-editor-buildCase-tab .nav,
.member-editor-buildCase-tab .nav li {
  position: relative;
  flex: 1;
}
.member-editor-buildCase-tab .nav-link {
  position: relative;
  z-index: 0;
  display: block;
  text-align: center;
  padding: 0.75rem 0.35rem !important;
  background-color: #e3e0de;
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}
@media (min-width: 1201px) {
  .member-editor-buildCase-tab .nav-link {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 1200px) {
  .member-editor-buildCase-tab .nav-link {
    font-size: 18px;
    font-size: 1.125rem;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}
.member-editor-buildCase-tab .nav li:hover > a, .member-editor-buildCase-tab .nav li.active > a {
  background-color: #eceae8;
}
.member-editor-buildCase-tab .nav::before {
  content: "";
  display: block;
  width: calc(100% - 70px);
  height: 50px;
  background-color: #e3e0de;
  position: absolute;
  top: 0px;
  left: 35px;
}
@media (min-width: 1201px) {
  .member-editor-buildCase-tab .nav {
    max-width: 68%;
  }
}
.member-editor-buildCase-tab .btn-wrap .btn {
  padding-bottom: 0.35em;
}
.member-editor-buildCase-tab .btn-wrap .btn .icon {
  margin-top: 0;
}
@media (min-width: 1201px) {
  .member-editor-buildCase-tab .btn-wrap {
    flex: 0 0 200px;
    margin: 0;
  }
}
@media (max-width: 1200px) {
  .member-editor-buildCase-tab .btn-wrap {
    order: -1;
    justify-content: flex-end;
  }
}
.member-editor-buildCase .form-group {
  margin-bottom: 0.25rem;
}
.member-editor-buildCase .icon {
  margin-top: -6px;
}
@media (max-width: 1200px) {
  .member-editor-buildCase .form-group {
    width: 100%;
    flex-direction: row !important;
  }
}
.member-editor-buildCaseAdd .form-group {
  align-items: center;
}
.member-editor-buildCaseAdd .form-group > label {
  min-width: 210px;
  line-height: 1.2;
}
@media (min-width: 1201px) {
  .member-editor-buildCaseAdd .form-group > label {
    padding-left: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .member-editor-buildCaseAdd .form-group {
    align-items: flex-start;
  }
  .member-editor-buildCaseAdd .form-group.flex-column {
    flex-direction: column !important;
  }
}
@media (max-width: 1200px) {
  .member-editor-buildCaseAdd .form-box {
    margin-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group {
    flex-direction: column !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .member-editor-buildCaseAdd .form-box > .form-group > label {
    padding-left: 0;
    padding-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group .form-input-size {
    width: 100% !important;
    flex: 1;
  }
  .member-editor-buildCaseAdd .form-box .twzipcode-style {
    width: calc(100% + 30px) !important;
  }
  .member-editor-buildCaseAdd .form-lineHref {
    margin-top: 0.75em;
    flex-direction: column;
  }
  .member-editor-buildCaseAdd .form-lineHref .form-input-size,
.member-editor-buildCaseAdd .form-lineHref .unit {
    width: 100% !important;
  }
  .member-editor-buildCaseAdd .form-lineHref .unit {
    padding-left: 0;
    padding-top: 5px;
  }
}

.empty-row {
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  max-width: 640px;
}
.empty-row .img-box {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.empty-row .img-box img {
  display: block;
  width: 100%;
}
.empty-row .content {
  margin-top: 20px;
  text-align: center;
}
.empty-row .content .title {
  color: #222;
  font-size: 32px;
  font-family: "Open Sans";
  line-height: 1.2;
  margin: 0;
}
.empty-row .content .des {
  color: #999;
  font-size: 17px;
  line-height: 1.6;
  font-family: "Open Sans";
  margin: 0;
  margin-top: 10px;
}

@media (max-width: 640px) {
  .empty-row .content {
    margin-top: 30px;
  }
  .empty-row .content .title {
    font-size: 26px;
  }
  .empty-row .content .des {
    font-size: 16px;
    margin-top: 15px;
  }
}
.error-row {
  background: url("../images/error-background.svg") no-repeat;
  background-position: center top;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.error-row .img-box {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
.error-row .img-box img {
  display: block;
  width: 100%;
}
.error-row .img-box .error-code {
  color: #555;
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 1;
  margin: 0;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-57%);
}
.error-row .content {
  margin-top: 50px;
  text-align: center;
}
.error-row .content .title {
  color: #444;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}
.error-row .content .des {
  margin-top: 25px;
}
.error-row .content .des p {
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}
.error-row .content .des p:not(:last-child) {
  margin-bottom: 5px;
}
.error-row .btn-row {
  margin-top: 40px;
  margin-bottom: 20px;
}
.error-row .btn-row .link {
  border-radius: 30px;
  background: #e8883b;
  color: #fff;
  display: block;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding: 15px 13px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: inherit;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 240px;
  line-height: 1;
}
.error-row .btn-row .link:hover {
  background: #5f4437;
}

@media (max-width: 1440px) {
  .error-row .img-box {
    max-width: 540px;
  }
  .error-row .img-box .error-code {
    font-size: 100px;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .error-row .img-box {
    max-width: 440px;
  }
  .error-row .img-box .error-code {
    font-size: 70px;
    top: 45%;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 640px) {
  .error-row .img-box .error-code {
    font-size: 50px;
    top: 45%;
  }
  .error-row .content {
    margin-top: 30px;
  }
  .error-row .content .title {
    font-size: 26px;
  }
  .error-row .content .des {
    margin-top: 15px;
  }
  .error-row .content .des p {
    font-size: 16px;
  }
  .error-row .btn-row {
    margin-top: 30px;
  }
  .error-row .btn-row .link {
    font-size: 17px;
    max-width: 170px;
  }
}
.emptyCart {
  max-width: 800px;
}

.emptyCart .img-box {
  text-align: center;
}

.emptyCart .img-box img {
  max-width: 14em;
  display: inline-block;
}

.emptyCart .content .title {
  font-size: 25px;
  font-weight: 200;
}

.emptyCart .content,
.emptyCart .btn-row {
  margin-top: 10px;
}

.emptyCart .btn-row .link {
  padding: 15px 30px;
  max-width: 100%;
  width: auto;
}

.searchResult-wrapper {
  position: relative;
  padding: 1.5em 2em 1.5em 1.5em;
  background-color: #f8f8f8;
}
.searchResult-wrapper .searchResult-info-list {
  background-color: #fff;
  padding: 1.25em 1.2em 1em;
}
.searchResult-wrapper .searchResult-info-list:hover {
  background-color: #eee;
}
.searchResult-wrapper .searchResult-info-list:not(:last-child) {
  margin-bottom: 1em;
}
.searchResult-wrapper .searchResult-info-head {
  padding-left: 20px;
  line-height: 1.75;
}
.searchResult-wrapper .searchResult-info-headTitle {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #020202;
  margin-bottom: 0.35em;
}
.searchResult-wrapper .searchResult-info-headsubTitle {
  --min-size: 16;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5d5d5e;
  line-height: 1.1;
}

.typing {
  width: 15ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
:root {
  --fancy-slider-theme-color: #fff;
  --fancy-slider-font-family: sans-serif;
  --fancy-slider-button-side-offset: 32px;
  --fancy-slider-button-mobile-side-offset: 12px;
  --fancy-slider-button-mobile-bottom-offset: 0px;
  --fancy-slider-head-font-size-s: 36px;
  --fancy-slider-head-font-size: 48px;
  --fancy-slider-head-font-size-b: 60px;
  --fancy-slider-title-font-size: 33px;
  --fancy-slider-title-font-family: $OpenSans-300;
  --fancy-slider-title-line-height: 1;
  /* Card */
  --card-transition-duration: 500ms;
  --card-transition-delay: 250ms; /* delay background color */
  /* background */
  --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
  --fancy-slider-transition-background-delay: 1000ms; /* delay background color */
  /* other */
  --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
  --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
  --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
  /* scaling */
  --fancy-slider-transform-scale-slide: 1; /* before scale in slide - same as in js */
  --fancy-slider-transform-scale-img: 1; /* before scale in img - same as in js */
  /* nav */
  --fancy-slider-transition-button: 400ms; /*  */
  --fancy-slider-transition-button-arrow: 400ms; /*  */
  --fancy-slider-transition-button-circle: 400ms; /*  */
  --bs-aspect-ratio-mobile: 100% ;
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.index-content {
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1200px) {
  .index-content {
    padding: 1.5em 1em;
  }
}
@media (min-width: 1201px) {
  .index-content .btn-wrap {
    width: auto;
    margin-top: 0;
    justify-content: start;
  }
}
.index-content .btn {
  flex: 1;
  max-width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.75em 1em 0.75em 0;
}
@media (min-width: 1201px) {
  .index-content .btn {
    justify-content: flex-start;
    padding-top: 0;
    margin: 0;
  }
}
.index-content .btn:hover {
  color: #5f4437 !important;
  border-color: transparent !important;
  background-color: transparent !important;
}
@media (max-width: 1200px) {
  .index-pageWrapper {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}
.index-pageSwiper {
  width: 100%;
  overflow: hidden;
}
.index-pageSwiper .swiper-wrapper {
  /* 通过改变animation-timing-function 制作弹性切换效果 */
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
.index-pageSwiper .swiper-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: -2em;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
  width: 47px;
  height: 47px;
  overflow: hidden;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev::after,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next::after {
  display: none;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next svg path {
  fill: #5f4437;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev.swiper-button-disabled svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next.swiper-button-disabled svg path {
  fill: #bfbfbf;
}
@media (min-width: 1201px) {
  .index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
    position: static;
  }
}
.index-pageArticle {
  line-height: 1.5;
}
.index-pageAbout {
  padding-top: 2.6315789474vw;
  padding-bottom: 3.1578947368vw;
}
.index-pageAbout .pageGrid {
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}
.index-pageAbout .pageAbout-img-left {
  margin-top: 10.2631578947vw;
}
@media (max-width: 1200px) {
  .index-pageAbout .pageAbout-img-left {
    margin-top: 0;
    margin-bottom: 1em;
  }
}
.index-pageAbout .pageAbout-img-right {
  margin-top: 7.8947368421vw;
  margin-left: auto;
}
.index-pageAbout .pageAbout-img-right .icon-animated-top {
  position: absolute;
  top: 2em;
  transform-origin: center;
  animation: animatedTop 3s infinite;
}
@media (max-width: 1300px) {
  .index-pageAbout .pageAbout-img-right .icon-animated-top {
    left: -1em;
  }
  .index-pageAbout .pageAbout-img-right .icon-animated-top svg, .index-pageAbout .pageAbout-img-right .icon-animated-top image {
    width: 5em;
    height: 5em;
  }
}
.index-pageAbout .pageAbout-img-right .icon-animated-bottom {
  position: absolute;
  bottom: 2em;
  left: 0;
  animation: animatedBottom 3s infinite;
}
@media (max-width: 1300px) {
  .index-pageAbout .pageAbout-img-right .icon-animated-bottom {
    bottom: 0;
  }
  .index-pageAbout .pageAbout-img-right .icon-animated-bottom svg, .index-pageAbout .pageAbout-img-right .icon-animated-bottom image {
    width: 5em;
    height: 5em;
  }
}
@media (max-width: 1200px) {
  .index-pageAbout .pageAbout-img-right {
    margin-right: -2em;
  }
}
@media (min-width: 1601px) and (max-width: 1700px) {
  .index-pageAbout .pageAbout-img-left, .index-pageAbout .pageAbout-img-right {
    max-width: 40em;
  }
}
@media (min-width: 1301px) and (max-width: 1599px) {
  .index-pageAbout .pageAbout-img-left, .index-pageAbout .pageAbout-img-right {
    max-width: 30em;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .index-pageAbout .pageAbout-img-left, .index-pageAbout .pageAbout-img-right {
    max-width: 25em;
  }
}
@keyframes animatedTop {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(75deg);
  }
}
@keyframes animatedBottom {
  0% {
    opacity: 0;
    bottom: 0;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@media (min-width: 1201px) {
  .index-pageAbout .pageAbout-info .heading-title {
    writing-mode: vertical-rl;
    line-height: 0.25;
    position: relative;
    top: -2em;
    left: 1em;
  }
  .index-pageAbout .pageAbout-info .heading-title-txt, .index-pageAbout .pageAbout-info .heading-title-txt-en {
    padding: 0;
    letter-spacing: 2px;
  }
  .index-pageAbout .pageAbout-info .heading-title-txt-en {
    padding-top: 3px;
  }
}
.index-pageAbout .pageAbout-info-article-head {
  --min-size: 22;
  --max-size: 36;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 2;
}
@media (max-width: 1200px) {
  .index-pageAbout .pageAbout-info-article-head {
    margin-bottom: 0.25em;
  }
}
.index-pageAbout .pageAbout-info-article .article {
  --min-size: 16;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (min-width: 1201px) {
  .index-pageAbout .pageAbout-info-article {
    writing-mode: vertical-rl;
    padding-left: 2.1052631579vw;
  }
  .index-pageAbout .pageAbout-info-article .article {
    width: auto;
    margin-bottom: 4em;
    line-height: 2.75;
  }
}
@media (min-width: 1201px) {
  .index-pageAbout .pageAbout-info {
    position: absolute;
    width: 100%;
    top: 10%;
    left: 50%;
    max-width: 30em;
    margin-left: -15em;
  }
}
@media (min-width: 1201px) {
  .index-pageAbout .btn .icon {
    margin-top: 15px;
  }
}
@media (max-width: 1200px) {
  .index-pageAbout .pageGrid {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.index-pageNews {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url(../images/index-pageNews-bg.png);
  padding-top: 4.7368421053vw;
  padding-bottom: 5.2631578947vw;
  margin-bottom: 1em;
}
.index-pageNews .heading-title {
  position: relative;
  z-index: 1;
}
.index-pageNews .heading-img {
  margin-top: -3.9em;
  z-index: 0;
}
@media (min-width: 1201px) {
  .index-pageNews .heading-img {
    left: -2.25em;
  }
}
@media (min-width: 1201px) {
  .index-pageNews .btn-wrap {
    margin-right: 1.25em;
    margin-top: 1.25em;
  }
  .index-pageNews .btn-wrap, .index-pageNews .btn-wrap .btn {
    justify-content: flex-end;
  }
  .index-pageNews .btn-wrap .btn {
    padding-right: 0;
  }
}
.index-pageNews .btn .icon {
  margin-right: 15px;
}
.index-pageNews .index-pageNews-list .list-group-item {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}
.index-pageNews .index-pageNews-list .list-group-item .time,
.index-pageNews .index-pageNews-list .list-group-item .txt {
  --min-size: 15;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.index-pageNews .index-pageNews-list .list-group-item .time {
  color: #7e7e7e;
}
@media (max-width: 1200px) {
  .index-pageNews .index-pageNews-list {
    margin-top: 1em;
  }
}
@media (max-width: 1200px) {
  .index-pageNews {
    padding-top: 12.5vw;
  }
}
.index-pagePlantTech {
  padding-top: 2.1052631579vw;
  padding-bottom: 4.2105263158vw;
}
@media (min-width: 1201px) {
  .index-pagePlantTech .pageGrid {
    align-items: center;
  }
  .index-pagePlantTech .pageGrid:nth-child(3) .index-pagePlantTech-img {
    width: 130%;
    margin-left: -20%;
  }
}
.index-pagePlantTech-img {
  margin-bottom: 1em;
}
.index-pagePlantTech-info {
  padding-left: 3.1578947368vw;
  padding-right: 3.1578947368vw;
}
.index-pagePlantTech-info-head {
  margin-bottom: 2.1052631579vw;
}
.index-pagePlantTech-info-headSubtitle {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Fira Sans", sans-serif, Arial;
  letter-spacing: 1px;
}
.index-pagePlantTech-info-headSubtitle::after {
  background-color: currentColor;
}
.index-pagePlantTech-info-headTitle {
  --min-size: 20;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.index-pagePlantTech-info-article, .index-pagePlantTech-info-step {
  margin-bottom: 1.8421052632vw;
}
.index-pagePlantTech-info-article {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.85;
}
.index-pagePlantTech-info-step {
  display: inline-block;
  background-color: #f4f0ec;
  padding: 16px 20px;
  border-radius: 1.75em;
  line-height: 1.25;
}
.index-pagePlantTech-info-step .icon {
  margin-left: 5px;
  margin-right: 5px;
}
@media (min-width: 1201px) {
  .index-pagePlantTech-info-step {
    padding-right: 4em;
  }
}
@media (max-width: 1200px) {
  .index-pagePlantTech-info-step {
    width: 100%;
  }
}
.index-pagePlantTech .btn {
  flex: none;
  color: #fff;
  border-radius: 1em !important;
  padding-top: 7px;
}
.index-pagePlantTech .btn .icon {
  margin-top: -5px;
  margin-right: 10px;
}
.index-pagePlantTech .btn:hover {
  background-color: #5f4437 !important;
  color: white !important;
}
.index-pageResume {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/index-pageResume-bg.jpg");
}
@media (min-width: 1201px) {
  .index-pageResume .titlebox {
    margin-top: 2em;
  }
}
.index-pageResume .card {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: transparent;
}
.index-pageResume .card-img-top {
  text-align: center;
  background: #fff;
  padding: 15px;
}
.index-pageResume .card-img-top img {
  display: inline-block;
  width: auto;
}
.index-pageResume .card-caption {
  text-align: center;
}
@media (min-width: 1201px) {
  .index-pageResume .card-caption {
    margin-top: 0;
  }
}
.index-pageResume .card .icon {
  margin-left: 10px;
}
.index-pageResume .pageFlex {
  flex-wrap: nowrap;
  justify-content: space-between;
}
.index-pageResume .pageFlex .titlebox {
  flex: none;
}
@media (max-width: 1200px) {
  .index-pageResume .pageFlex {
    flex-direction: column;
  }
}
.index-pageResume .swiper-container {
  max-width: 680px;
  margin-left: auto;
  overflow: hidden;
}
.index-pageResume .swiper-button {
  width: 130px;
  display: flex;
  justify-content: space-between;
  margin: 2em auto auto;
}
.index-pageResume .swiper-button-prev, .index-pageResume .swiper-button-next {
  position: static;
  width: 100%;
  max-width: 55px;
  height: 55px;
  border-radius: 50%;
  border: #8cbb59 1px solid;
}
.index-pageResume .swiper-button-prev path, .index-pageResume .swiper-button-next path {
  fill: #8cbb59;
}
.index-pageResume .swiper-button-prev::after, .index-pageResume .swiper-button-next::after {
  display: none;
}
.index-pageResume .swiper-button-disabled {
  opacity: 1 !important;
  border-color: #7d7d7d;
}
.index-pageResume .swiper-button-disabled path {
  fill: #7d7d7d;
}
@media (min-width: 1201px) {
  .index-pageResume .swiper-button {
    position: absolute;
    left: 0em;
    top: 10em;
    margin-top: 0;
  }
}
.index-pageResume-bottom {
  bottom: -1.5em;
}
@media (min-width: 1201px) {
  .index-pageResume-bottom {
    position: absolute;
    bottom: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .index-pageResume-bottom {
    max-width: 36em;
  }
}
@media (min-width: 1201px) {
  .index-pageResume {
    padding-top: 3em;
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .index-pageResume {
    padding-left: 3em;
    padding-right: 4em;
  }
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
@media (min-width: 1201px) {
  .page-banner.index .fancy-slider-title {
    position: absolute;
  }
  .page-banner.index .fancy-slider-scale {
    position: relative;
  }
}
@media (max-width: 1200px) {
  .page-banner.index {
    height: 46.875vw;
  }
}
@media (max-width: 640px) {
  .page-banner-main img {
    height: 6em;
    object-fit: cover;
    object-position: center;
  }
}
.page-gallerSwiper {
  overflow: hidden;
}
.page-gallerSwiper .swiper-pagination {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.page-gallerSwiper .swiper-pagination-bullet {
  width: 50px;
  height: 5px;
  border-radius: 0;
  margin: 0 !important;
}
.page-gallerSwiper .swiper-pagination-bullet-active {
  background-color: #5f4437;
}
.page-gallerSwiper .card-body {
  padding: 0;
}
.page-gallerSwiper .card-title {
  font-weight: 400;
  line-height: 1.15;
  word-wrap: normal;
}
.page-gallerSwiper .card-img-top {
  text-align: center;
}
.page-gallerSwiper .card-img-top img {
  display: inline-block;
}
.page-gallerSwiper .swiper-pagination {
  bottom: 0.5263157895vw !important;
}
@media (min-width: 760px) {
  .page-gallerSwiper .swiper-pagination-bullet {
    opacity: 0.2;
  }
  .page-gallerSwiper .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
@media (max-width: 1200px) {
  .page-gallerSwiper .swiper-pagination {
    justify-content: center;
  }
}
.page-gallerSwiper-pro {
  width: 100%;
  padding-top: 0.7894736842vw;
  padding-bottom: 2.1052631579vw;
}
.page-gallerSwiper-pro .card-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1201px) {
  .page-gallerSwiper-pro .card-body {
    position: absolute;
    padding: 3em;
    color: #fff;
    background-color: rgba(49, 49, 49, 0.85);
  }
}
.page-gallerSwiper-pro .card-title {
  font-size: 42px;
  font-size: 2.625rem;
  font-family: var(--fancy-slider-title-font-family);
  border-bottom: #282727 1px solid;
  text-align: center;
  padding-bottom: 0.3em;
  margin-bottom: 0.35em;
}
@media (min-width: 1201px) {
  .page-gallerSwiper-pro .card-title {
    border-bottom-color: rgba(255, 255, 255, 0.65);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1201px) {
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body {
    opacity: 0;
    transition: var(--card-transition-duration);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transition-duration: var(--card-transition-duration);
    transition-delay: var(--card-transition-delay);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title {
    transform: translateY(-1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transform: translateY(1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body {
    opacity: 1;
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-des {
    transform: translateY(0);
  }
}
@media (max-width: 810px) {
  .page-gallerSwiper-pro .card {
    flex-direction: row;
    align-items: flex-start;
    background-color: transparent;
  }
  .page-gallerSwiper-pro .card-img-top {
    border-radius: 20px;
    overflow: hidden;
    flex-basis: 150px;
    margin-top: 46px;
  }
  .page-gallerSwiper-pro .card-body {
    padding-left: 1.25em;
    padding-right: 0.25em;
    flex: 0 0 calc(100% - 150px);
  }
  .page-gallerSwiper-pro .card-title {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
.page-gallerSwiper-pro .swiper-slide .card {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 1201px) {
  .page-gallerSwiper-pro .swiper-slide-active .card, .page-gallerSwiper-pro .swiper-slide-next .card {
    border-bottom-right-radius: 0 !important;
  }
  .page-gallerSwiper-pro .swiper-slide-active .card {
    border-top-left-radius: 40%;
  }
  .page-gallerSwiper-pro .swiper-slide-next .card {
    border-radius: 20px;
  }
  .page-gallerSwiper-pro .swiper-slide .card {
    border-bottom-right-radius: 40%;
    overflow: hidden;
  }
}
.page-gallerSwiper-pro .swiper-act-container {
  align-items: flex-end;
  justify-content: end;
  margin-bottom: 2.75em;
  padding-right: 1em;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination {
  position: relative;
  width: auto;
  top: 0px;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
  position: absolute;
  top: 25px;
  z-index: -1;
}
.page-gallerSwiper-pro .swiper-act-button {
  width: calc(3.5em + 10px);
  padding-left: 10px;
  justify-content: space-between;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev {
  position: static;
  color: #282727;
  height: auto;
  margin-top: 0;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after {
  font-size: 14px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction span {
  color: #b9b9b9;
  font-size: 15px;
  font-family: "Open Sans";
  padding: 0 2px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-empty {
  width: 30px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current {
  position: relative;
  color: #282727;
  font-weight: 600;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background-color: #5f4437;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1200px) {
  .page-gallerSwiper-pro {
    display: flex;
    flex-direction: column;
  }
  .page-gallerSwiper-pro .swiper-act-container {
    order: 1;
    margin-top: 1.1em;
  }
}
.pageGrid {
  display: grid;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
}
.pageGrid-list-flAuto {
  grid-template-columns: repeat(1, auto) 1fr;
}
.pageGrid-list-frAuto {
  grid-template-columns: 1fr repeat(1, auto);
}
.pageGrid-list-1to1 {
  grid-template-columns: 1fr 1fr;
}
.pageGrid-list-2to1 {
  grid-template-columns: 2fr 1fr 1fr;
}
.pageGrid-list-avg {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.pageGrid-list-1to2 {
  grid-template-columns: 1fr 1fr 2fr;
}
@media (max-width: 1080px) {
  .pageGrid {
    grid-template-columns: auto !important;
  }
}
/* swiper : 首頁 */
.fancy-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: var(--fancy-slider-font-family);
  --fancy-slider-title-font-size: 88px;
}
.fancy-slider .swiper {
  width: 100%;
  height: 100%;
  transition-duration: var(--fancy-slider-transition-background-duration);
  transition-property: background-color;
  transition-delay: var(--fancy-slider-transition-background-delay);
}
.fancy-slider .swiper-wrapper {
  transition-delay: var(--fancy-slider-transition-slide-delay);
  transition-timing-function: var(--fancy-slider-transition-timing-function);
}
.fancy-slider .swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fancy-sliderSwiper-sec04 {
  align-items: flex-end !important;
  justify-content: flex-end !important;
}
@media (min-width: 1201px) {
  .fancy-sliderSwiper-sec04 .fancy-slider-title-head {
    top: -5.7894736842vw;
    left: -12.6315789474vw;
  }
}

.fancy-slider-no-transition {
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
  transition-property: none !important;
}
.fancy-slider-no-transition *:not(.swiper-wrapper) {
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
  transition-property: none !important;
}
.fancy-slider-no-transition .swiper-wrapper {
  transition-delay: 0ms !important;
}

.fancy-slider-title {
  transition-delay: var(--fancy-slider-transition-title-delay);
  z-index: 10;
}
.fancy-slider-title-head {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2.1052631579vw;
  margin-bottom: 1.0526315789vw;
  transition-delay: var(--fancy-slider-transition-title-delay);
}
@media (min-width: 1201px) {
  .fancy-slider-title-head {
    left: 10vw;
  }
}
.fancy-slider-title-text {
  font-weight: 900;
  font-size: 5.46875vw;
  line-height: var(--fancy-slider-title-line-height);
  font-family: var(--fancy-slider-title-font-family);
  letter-spacing: -1px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 2px #fff;
}
@media (min-width: 1201px) {
  .fancy-slider-title-text {
    font-size: 3.9473684211vw;
  }
}
@media (max-width: 1200px) {
  .fancy-slider-title-text {
    color: rgb(255, 255, 255) !important;
  }
}
.fancy-slider-title-subtext {
  letter-spacing: 3px;
  font-size: 2.5vw;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 2px #fff;
}
@media (min-width: 1201px) {
  .fancy-slider-title-subtext {
    font-size: 1.1578947368vw;
  }
}

.fancy-slider-scale {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(var(--fancy-slider-transform-scale-slide));
  transition-property: transform;
  transition-timing-function: var(--fancy-slider-transition-timing-function);
  opacity: 0.9;
  overflow: hidden;
}
.fancy-slider-scale img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(var(--fancy-slider-transform-scale-img));
  transition-timing-function: var(--fancy-slider-transition-timing-function);
  transition-property: transform;
}
@media (max-width: 640px) {
  .fancy-slider-scale img {
    height: 200px;
  }
}

.fancy-slider-button {
  transition: var(--fancy-slider-transition-button);
  outline: none;
  position: absolute;
  width: 140px;
  z-index: 10;
  top: 70%;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .fancy-slider-button {
    width: 100px;
  }
}
.fancy-slider-button svg {
  display: block;
  transition: var(--fancy-slider-transition-button);
}
.fancy-slider-button .fancy-slider-svg-circle-wrap {
  transition: var(--fancy-slider-transition-button-circle);
  transform-origin: -20px 40px;
  opacity: 1;
}
.fancy-slider-button circle {
  transition: var(--fancy-slider-transition-button-circle);
  stroke-width: 2px;
  stroke: #5f4437;
  fill: none;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  opacity: 1;
  transform-origin: 0px 0px 0px;
}
.fancy-slider-button .fancy-slider-svg-arrow {
  transition: var(--fancy-slider-transition-button-arrow);
  fill: #5f4437;
  transform: rotateY(180deg) translate(-49px, 31.1px) scale(3);
}
.fancy-slider-button .fancy-slider-svg-wrap {
  transform: translateY(353px);
}
.fancy-slider-button .fancy-slider-svg-line {
  transition: var(--fancy-slider-transition-button);
  stroke: #5f4437;
  stroke-width: 2;
  transform: translate(50px, 42px);
}
.fancy-slider-button:not(.fancy-slider-button-disabled):hover circle {
  stroke-dasharray: 4px;
  stroke-dashoffset: 4px;
  opacity: 1;
}

.fancy-slider-button-prev {
  left: var(--fancy-slider-button-side-offset);
}
.fancy-slider-button-prev:not(.fancy-slider-button-disabled):hover svg {
  transform: translate(-16px);
}

.fancy-slider-button-next {
  right: var(--fancy-slider-button-side-offset);
}
.fancy-slider-button-next .fancy-slider-svg-wrap {
  transform: translateY(353px) rotateY(180deg);
  transform-origin: 80px 0px 0px;
}
.fancy-slider-button-next:not(.fancy-slider-button-disabled):hover svg {
  transform: translate(16px);
}

.fancy-slider-button-disabled {
  opacity: 0.2;
  cursor: default;
}

.fancy-slider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.fancy-slider .swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.fancy-slider .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  transform: translate(0);
}
.fancy-slider .swiper-android .swiper-slide {
  transform: translate(0);
}
.fancy-slider .swiper-pointer-events {
  touch-action: pan-y;
}
.fancy-slider .swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}
.fancy-slider .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.fancy-slider .swiper-slide-invisible-blank {
  visibility: hidden;
}
.fancy-slider .swiper-autoheight {
  height: auto;
}
.fancy-slider .swiper-autoheight .swiper-slide {
  height: auto;
}
.fancy-slider .swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}
.fancy-slider .swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}
.fancy-slider .swiper-3d {
  perspective: 1200px;
}
.fancy-slider .swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d .swiper-slide {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d .swiper-slide-shadow {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background: rgba(0, 0, 0, 0.15);
}
.fancy-slider .swiper-3d .swiper-slide-shadow-left {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-right {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-top {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-bottom {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}
.fancy-slider .swiper-horizontal.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}
.fancy-slider .swiper-vertical.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}
.fancy-slider .swiper-centered > .swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}
.fancy-slider .swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}
.fancy-slider .swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}
.fancy-slider .swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  height: 100%;
  width: var(--swiper-centered-offset-after);
}
.fancy-slider .swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}
.fancy-slider .swiper-centered.swiper-vertical > .swiper-wrapper:before {
  width: 100%;
  height: var(--swiper-centered-offset-after);
}
@media (max-width: 480px) {
  .fancy-slider .fancy-slider {
    --fancy-slider-title-font-size: 60px;
  }
}
@media (max-width: 640px) {
  .fancy-slider .fancy-slider-button-prev {
    left: var(--fancy-slider-button-mobile-side-offset);
  }
  .fancy-slider .fancy-slider-button-next {
    right: var(--fancy-slider-button-mobile-side-offset);
  }
}

.news-list-group .list-group-item {
  background-color: transparent;
  display: flex;
  border-width: 1px 0 0 0;
  border-radius: 0;
  border-color: #000;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.news-list-group .list-group-item .time,
.news-list-group .list-group-item .txt {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.news-list-group .list-group-item .time {
  line-height: 1.25;
}
.news-list-group .list-group-item .txt-time {
  --min-size: 20;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-family: "Fira Sans", sans-serif, Arial;
  margin-bottom: 5px;
}
@media (min-width: 1201px) {
  .news-list-group .list-group-item .txt-time {
    margin-bottom: 1.75rem;
  }
}
.news-list-group .list-group-item:last-child {
  border-bottom-width: 1px;
}
.news-list-group .list-group-item:hover {
  background-color: #f6f3f2;
}
@media (min-width: 1201px) {
  .news-list-group .list-group-item {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
.news-detail-wrap .list-group-item:hover {
  background-color: transparent;
}
@media (max-width: 1200px) {
  .news-detail-wrap .list-group-item {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.accordion-card .btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  color: #000 !important;
  background-color: #fff !important;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
.accordion-card .btn::after {
  content: "";
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-card-flex {
  padding: 10px;
  --min-size: 18;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.accordion-card-num, .accordion-card-title {
  padding-right: 50px;
}
@media (max-width: 1200px) {
  .accordion-card {
    margin-top: 1.5em;
  }
  .accordion-card-flex {
    align-items: flex-start;
    padding: 0;
  }
  .accordion-card-num {
    padding-right: 1em;
  }
  .accordion-card-title {
    padding-top: 2px;
  }
  .accordion-card-name {
    width: 100%;
    padding-top: 0.75em;
  }
  .accordion-card-des {
    width: calc(100% - 2em);
  }
}
.accordion-button {
  background-color: #fff !important;
  border-radius: 0 !important;
  border-top: 1px solid #d2d2d2;
}
.accordion-button:not(.collapsed) {
  color: #000;
  border-top-color: #000;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.accordion-body {
  background-color: #f6f3f2;
}
.accordion-item {
  border: none;
}
.accordion-item:last-child .accordion-button {
  border-bottom: 1px solid #d2d2d2;
}

.about-incontent {
  padding: 3em 1em;
  overflow: hidden;
}
.about-incontent-style01 {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/about-cont-style01.png");
  padding-bottom: 24em;
}
@media (max-width: 1200px) {
  .about-incontent-style01 {
    padding-bottom: 16em !important;
  }
}
.about-incontent-style02 {
  background-color: #f6f5f5;
  z-index: -1;
}
@media (min-width: 1201px) {
  .about-incontent-style02 {
    margin-top: -6em;
    padding-bottom: 6em;
  }
}
@media (max-width: 1200px) {
  .about-incontent-style02 {
    padding-bottom: 3em !important;
  }
}
.about-incontent-style01, .about-incontent-style02 {
  padding-top: 9em;
}
@media (max-width: 1200px) {
  .about-incontent-style01, .about-incontent-style02 {
    padding-top: 4em;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .about-incontent {
    padding-left: 3em;
    padding-right: 4em;
  }
}
@media (max-width: 1200px) {
  .about-incontent {
    padding: 1.5em;
  }
}
.about-awards-txt {
  position: relative;
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #b89c8c;
  border-bottom: transparent 1px solid;
}
.about-awards-txt:not(:last-child) {
  margin-bottom: 0.75em;
}
.about-awards-txt .txt_num {
  position: relative;
  z-index: 1;
  font-family: "turbinado-pro", sans-serif, Arial;
  --min-size: 22;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-right: 1em;
  color: #b19583;
}
.about-awards-txt .txt_num::after {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background-color: #dfd9d1;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: -5px;
  z-index: -1;
  opacity: 0;
}
.about-awards-txt .txt_arrow_right {
  position: absolute;
  right: 0;
  opacity: 0;
}
.about-awards-txt:hover, .about-awards-txt.active {
  color: #5f4437;
  border-color: #5f4437;
}
.about-awards-txt:hover .txt_num::after, .about-awards-txt.active .txt_num::after {
  opacity: 1;
}
.about-awards-txt:hover .txt_arrow_right, .about-awards-txt.active .txt_arrow_right {
  opacity: 1;
}
.about-awards-wrapper {
  z-index: 1;
}
.about-awards-list {
  z-index: 1;
}
.about-awards-list-img {
  position: absolute;
  left: -2.5em;
  top: 85%;
  width: 775px;
}
@media (max-width: 1200px) {
  .about-awards-list-img {
    left: 0;
    top: auto;
    bottom: -100%;
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .about-awards-list {
    margin-bottom: 1em;
  }
}
.about-awards-img .img.d-block {
  display: block !important;
}
@media (max-width: 1200px) {
  .about-awards-img {
    margin-top: 1em;
  }
}
.about-titlebox-head {
  --min-size: 22.5;
  --max-size: 30;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.about-titlebox-title {
  --min-size: 20;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.about-titlebox-num {
  font-family: "Allura", cursive, Arial;
  --min-size: 25;
  --max-size: 60;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 0.85;
}
.about-titlebox-num::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  max-width: 140px;
  min-height: 140px;
  border-radius: 50%;
  background-color: #f6f3f2;
  position: absolute;
  top: -30px;
  z-index: -1;
  left: -40px;
}
@media (max-width: 1200px) {
  .about-titlebox-num::after {
    max-width: 70px;
    min-height: 70px;
    left: -20px;
    top: -25px;
  }
}
@media (min-width: 1201px) {
  .about-articlebox {
    padding-left: 4rem;
  }
}
.about-stepbox {
  margin-top: 0.75em;
  margin-bottom: 1.5em;
}
@media (max-width: 1200px) {
  .about-stepbox .about-titlebox {
    margin-top: 0.5em;
  }
  .about-stepbox .about-articlebox {
    margin-bottom: 4em;
  }
}
.about-step-path {
  position: absolute;
}
.about-step-path-01 {
  bottom: -12em;
  right: 6em;
}
.about-step-path-02 {
  top: 80%;
  left: 57.5px;
}
.about-step-path-03 {
  bottom: -57.5px;
  left: 50%;
  width: 115px;
}
.about-step-path-04 {
  bottom: -172.5px;
  right: 115px;
}
.about-step-path-05 {
  left: calc(47% - 115px);
  top: calc(50% + 115px);
}
.about-step-path-06 {
  right: 115px;
  top: calc(80% + 115px);
}
.about-step-path-07 {
  top: 85%;
  left: 57.5px;
}
.about-step-path-08 {
  bottom: -172.5px;
  right: 38.3333333333px;
}
.about-step-path .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2.5s linear alternate infinite;
}
@media (max-width: 1200px) {
  .about-step-path {
    display: none;
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
    fill-opacity: 0;
  }
  to {
    fill-opacity: 1;
    stroke-dashoffset: 0;
  }
}
@media (min-width: 1201px) {
  .aboutMax-img-140p {
    width: 140%;
    left: -30%;
  }
}

@media (min-width: 1201px) {
  .pro-card-wrap {
    margin-top: 3em;
  }
}
@media (min-width: 1700px) {
  .pro-card-wrap {
    margin-left: -2em;
    margin-right: -2em;
  }
}
.pro-card-wrap .card {
  background-color: transparent;
  overflow: hidden;
}
.pro-card-wrap .card:hover {
  background-color: #fff;
}
.pro-card-wrap .card-img-top {
  border-radius: 0.5em;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
}
.pro-card-wrap .card-img-top img {
  transition: 0.25s;
}
.pro-card-wrap .card-cart {
  position: absolute;
  width: 100%;
  height: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1em;
  padding-top: calc(100% - 1em);
  z-index: 2;
}
@media (min-width: 1201px) {
  .pro-card-wrap .card-cart {
    opacity: 0;
    width: auto;
    height: auto;
    top: 13vw;
    right: 0;
    padding-top: 1em;
    pointer-events: auto;
  }
  .pro-card-wrap .card-cart:hover {
    opacity: 1;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .pro-card-wrap .card-cart {
    top: 10.5vw;
  }
}
@media (max-width: 1200px) {
  .pro-card-wrap .card-cart {
    opacity: 1;
    width: 2.5em;
    height: 2.5em;
    padding-top: 0;
    padding: 0;
    right: 1em;
    top: calc(100% - 129px);
  }
  .pro-card-wrap .card-cart svg, .pro-card-wrap .card-cart image {
    width: 2.5em;
    height: 2.5em;
  }
}
.pro-card-wrap .card-body {
  padding: 1em;
}
@media (max-width: 1200px) {
  .pro-card-wrap .card-body {
    padding: 0.5em;
  }
}
.pro-card-wrap .card-title {
  --min-size: 16;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0.25rem;
  line-height: 1.05;
}
.pro-card-wrap .card-btn {
  width: auto;
  border-radius: 30px !important;
}
@media (min-width: 1201px) {
  .pro-card-wrap .card-linkNormal:hover ~ .card-cart, .pro-card-wrap .card-linkNormal:focus ~ .card-cart {
    opacity: 1;
  }
  .pro-card-wrap .card-linkNormal:hover ~ .card-img-top img, .pro-card-wrap .card-linkNormal:focus ~ .card-img-top img {
    transform: scale(1.25);
  }
  .pro-card-wrap .card-linkNormal:hover ~ .card-body .card-btn, .pro-card-wrap .card-linkNormal:focus ~ .card-body .card-btn {
    background-color: #5f4437 !important;
  }
}
@media (min-width: 1201px) {
  .pro-card-wrap .card {
    margin-bottom: 3em;
  }
}

.price-old {
  text-decoration: line-through;
  color: #777;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.price-cur {
  --min-size: 24;
  --max-size: 25;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.price-cur ~ .price-old {
  padding-left: 10px;
}

:root {
  --headingItem: #040404;
  --headingDes: #535353;
}

.pro-heading-num {
  --min-size: 16;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: var(--headingItem);
  font-family: "good-times", sans-serif;
}
.pro-heading-txt {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: var(--headingItem);
}
.pro-heading-des {
  color: var(--headingDes);
  border-top: 1px solid #bfbfbf;
  padding-top: 25px;
  margin-top: 25px;
  margin-bottom: 45px;
}

@media (min-width: 1201px) {
  .Prod-conInfo {
    display: flex;
    align-items: flex-start;
    margin-top: 3.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
.Prod-conInfo-img {
  overflow: hidden;
}
.Prod-conInfo-img .swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Prod-conInfo-img .swiper-slide {
  background-color: #fff;
  overflow: hidden;
}
.Prod-conInfo-img .swiper-slide img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  margin: auto;
  border-radius: 0.5em;
}
.Prod-conInfo-img .swiper-button {
  display: none;
  width: 100%;
  z-index: 1;
}
@media (min-width: 1201px) {
  .Prod-conInfo-img .swiper-button {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    position: absolute;
    left: 50px;
  }
  .Prod-conInfo-img .swiper-button-prev, .Prod-conInfo-img .swiper-button-next {
    margin: 0;
    transform: rotate(90deg);
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .Prod-conInfo-img .swiper-button {
    left: 25px;
  }
}
.Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
  position: static;
  color: #5f4437;
  width: 40px;
  height: 40px;
}
.Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after {
  font-size: 20px;
  font-size: 1.25rem;
}
@media (min-width: 1201px) {
  .Prod-conInfo-img {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: 100%;
    height: 41em;
    padding-right: 4em;
  }
  .Prod-conInfo-img .swiper.Prod_mySwiper2 {
    width: 85%;
    order: 1;
    padding-left: 1em;
  }
  .Prod-conInfo-img .swiper.Prod_mySwiper {
    width: 15%;
    height: calc(100% - 4em);
    margin: 2em 0;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .Prod-conInfo-img {
    height: 36em;
    padding-right: 1.5em;
  }
}
@media (max-width: 1200px) {
  .Prod-conInfo-img {
    margin-top: 1em;
  }
}
.Prod-conInfo-img .Prod_mySwiper2 {
  height: 80%;
  width: 100%;
  margin-bottom: 0.25em;
}
.Prod-conInfo-img .Prod_mySwiper2 .swiper-slide {
  padding-bottom: 100%;
}
@media (min-width: 1201px) {
  .Prod-conInfo-img .Prod_mySwiper2 {
    height: 100%;
  }
  .Prod-conInfo-img .Prod_mySwiper2 .swiper-slide {
    padding-bottom: 70%;
  }
}
.Prod-conInfo-img .Prod_mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide {
  opacity: 0.5;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
@media (max-width: 1200px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide {
    padding-bottom: 29%;
  }
}
@media (min-width: 1201px) {
  .Prod-conInfo-img .Prod_mySwiper {
    height: 100%;
    padding: 0;
  }
}
.Prod-conInfo-info {
  padding-top: 25px;
}
@media (min-width: 1201px) {
  .Prod-conInfo-info {
    flex: 1;
  }
}
.Prod-heading {
  display: flex;
  line-height: 1.5;
  padding-bottom: 0.25em;
  margin-bottom: 0;
  font-size: 24px;
}
.Prod-headingTitle {
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.Prod-headingTitle-item {
  color: #777;
  padding-right: 1em;
}
@media (min-width: 1201px) {
  .Prod-headingTitle {
    margin-bottom: 20px;
  }
  .Prod-headingTitle-item {
    padding-left: 2em;
    padding-right: 2em;
  }
}
.Prod-conTxt {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.Prod-conTxt.total {
  border: none;
  padding: 0;
  margin: 0;
}
.Prod-conTxt.total .Prod-headingTitle-item {
  color: #000;
}
.Prod-conSpec {
  text-align: center;
}
.Prod-conSpec-item {
  flex-shrink: 1;
  margin-bottom: 15px;
}
.Prod-conSpec-itemImg img {
  max-width: 40px;
}
.Prod-conSpec-itemNumber {
  border-bottom: #cecece 1px solid;
}
.Prod-conSpec-itemNumber_count {
  color: #626262;
}
.Prod-conSpec-item div {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  padding-bottom: 2px;
}
.Prod-socialIcons {
  display: flex;
  margin-top: 30px;
}
.Prod-socialIcons li {
  margin-right: 10px;
}
.Prod-unreset {
  padding-top: 1.5em;
}
@media (min-width: 1201px) {
  .Prod-unreset {
    margin-top: 4.5em;
  }
}
.Prod-unreset .nav-tabs {
  border-top: #bfbfbf 1px solid;
  border-bottom: 1px #bfbfbf solid;
}
@media (min-width: 1201px) {
  .Prod-unreset .nav-tabs {
    padding-left: 5.2631578947vw;
    padding-right: 5.2631578947vw;
  }
}
.Prod-unreset .nav-item {
  min-height: auto;
}
.Prod-unreset .nav-item::before {
  display: none !important;
}
.Prod-unreset .nav-link {
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  color: #7d7c7c;
  background-color: transparent !important;
  border: none;
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.Prod-unreset .nav-link.active {
  color: #a3938c !important;
}
.Prod-unreset .nav-link.active::after {
  content: "";
  display: block;
  width: 70%;
  height: 3px;
  background-color: #5f4437;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 1201px) {
  .Prod-unreset .nav-link {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 2.5;
  }
}
.Prod-unreset .tab-content {
  padding: 1em;
}
@media (min-width: 1201px) {
  .Prod-unreset .tab-content {
    padding: 4em 2em;
  }
}
.Prod-btn {
  justify-content: center;
}
.Prod-btn .btn {
  border-radius: 0.3em !important;
  border-color: currentColor !important;
}
@media (min-width: 1201px) {
  .Prod-btn .btn {
    font-size: 18px;
    padding: 0.4210526316vw 1.6842105263vw;
  }
}
@media (max-width: 1200px) {
  .Prod-btn .btn {
    flex: 1;
  }
}

.counter {
  width: 150px;
  border: #e5e5e5 1px solid;
  border-radius: 0.25em;
  text-align: center;
}
.counter .list {
  flex: 1;
  margin: 0;
  padding: 0.5em 0;
}
.counter a {
  display: block;
}
.counter .numtxt {
  font-size: 18px;
}
.member-form-inputbox {
  border: #d2d2d2 1px solid;
  border-radius: 0.5em;
}
.member-form-inputbox .form-group .form-control {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1.5em;
}
.member-form-inputbox .form-group .form-flex label {
  min-width: 140px;
}
.member-form-inputbox-login .form-group:first-child {
  border-bottom: #d2d2d2 1px solid;
}
.member-form-inputbox-login .form-group:last-child {
  border-top: #d2d2d2 1px solid;
}
.member-formbtn .btn {
  font-size: 18px;
  border-radius: 0.5em !important;
}
.member-formbtn .btn-outline-main {
  border-color: #5f4437 !important;
}
@media (min-width: 1201px) {
  .member-formbtn .btn {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }
}
.member-login-other-head {
  height: 1px;
  background: #cecece;
  position: relative;
}
.member-login-other-head h3 {
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  color: #010101;
  background: #fff;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
  padding: 0 10px;
}
@media (max-width: 1200px) {
  .member-login-other-head h3 {
    min-width: 15em;
  }
}
.member-login-other-social {
  margin-top: 2rem;
}
.member-disabled, .member-disabled .form-control:disabled {
  background-color: #e9ecef;
}
.member-borderTop {
  border-top: #d2d2d2 1px solid;
}
.member-borderTottom {
  border-bottom: #d2d2d2 1px solid;
}
.memberorder-tablebox-thead {
  border: none !important;
  background-color: #fff !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@media (max-width: 1200px) {
  .memberorder-tablebox-thead {
    display: none;
  }
}
.memberorder-tablebox .cart-table {
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
}
.memberorder-tablebox .cart-table table td {
  font-size: 16px;
  line-height: 1.25;
}
@media (min-width: 1201px) {
  .memberorder-tablebox .cart-table table td {
    text-align: center !important;
  }
}
@media (max-width: 1200px) {
  .memberorder-tablebox .cart-table table td {
    display: flex;
    flex-wrap: wrap;
  }
  .memberorder-tablebox .cart-table table td:not(:last-child) {
    border-bottom: #e8dede 1px solid;
  }
  .memberorder-tablebox .cart-table table td::before {
    content: attr(data-title);
    display: inline-block;
    min-width: 80px;
    margin-right: 1rem;
    margin-top: 0.15rem;
    color: #777;
    font-size: 0.875em;
  }
}
.memberorder-tablebox .cart-table table tbody tr {
  border: 1px solid #cebfba;
  border-radius: 0.4em;
  padding: 0.25em 1em;
  margin-bottom: 0.75rem !important;
}
.memberorder-tablebox .cart-table table tbody tr:nth-child(even) {
  background-color: #f6f3f2;
}
@media (min-width: 1201px) {
  .memberorder-tablebox .cart-table table tbody tr.list-grid-7 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.65fr;
  }
}
@media (max-width: 1200px) {
  .memberorder-tablebox .cart-table table tbody tr {
    padding: 0 0.25em;
  }
}
@media (min-width: 1201px) {
  .memberorderdetail-tablebox .cart-table {
    padding: 0.5em 1.5em;
  }
}
@media (max-width: 1200px) {
  .memberorderdetail-tablebox .cart-member-table-head {
    font-size: calc(1rem + 0.6vw);
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 1200px) {
  .memberorderdetail-tablebox .cart-member-table .cart-checkout-info table tbody tr {
    display: flex;
    align-items: center;
  }
  .memberorderdetail-tablebox .cart-member-table .cart-checkout-info table tbody tr td {
    line-height: 1.25;
    padding-top: 0.25em !important;
    padding-bottom: 0.25em !important;
  }
  .memberorderdetail-tablebox .cart-member-table .cart-checkout-info table tbody tr td, .memberorderdetail-tablebox .cart-member-table .cart-checkout-info table tbody tr td * {
    font-size: 16px !important;
  }
  .memberorderdetail-tablebox .cart-member-table .cart-checkout-info table tbody tr td:first-child {
    min-width: 100px;
  }
}

.layui-input-inline {
  position: relative;
}
.layui-input-inline .form-control {
  border: none;
}
@media (max-width: 1200px) {
  .layui-input-inline {
    width: 100% !important;
  }
}
.layui-form-label {
  border-color: #e6e6e6;
  position: relative;
  left: 10px;
  top: -8px;
  font-size: 14px;
}
.layui-form-label {
  float: left;
  display: block;
  padding: 9px 15px;
  width: 80px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
}
.layui-form-item {
  margin-bottom: 15px;
  clear: both;
  *zoom: 1;
}
.layui-form-item::after {
  content: " ";
  clear: both;
  *zoom: 1;
  display: block;
  height: 0;
}
.layui-form-item .layui-input-inline {
  float: left;
  width: 190px;
  margin-right: 10px;
}

.x-red {
  color: red;
  position: relative;
  top: 3px;
}

.password, .password .n-txtCss {
  display: flex;
  align-items: center;
}
.password .eyes {
  width: 31px;
  height: 26px;
  position: absolute;
  display: inline-block;
  right: 11px;
  top: 14px;
  cursor: pointer;
}
.password .eyes-confirmpwd {
  right: -2.5em;
}
@media (max-width: 1200px) {
  .password .eyes-confirmpwd {
    right: 3em;
  }
}
.password .none {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

:root {
  --breadcrumb-padding-x:1.25rem;
}
@media (max-width: 1200px) {
  :root {
    --breadcrumb-padding-x:0.25rem;
  }
}

.cart-step-badge {
  width: 40px;
  height: 40px;
  line-height: 39px;
  font-size: 18px;
  font-weight: 100;
  background: #000;
  border-radius: 50%;
  color: #fff;
  padding: 0;
}
@media (max-width: 1200px) {
  .cart-step-badge {
    width: 30px;
    height: 30px;
    line-height: 29px;
    font-size: 15px;
  }
}
.cart-step .breadcrumb {
  flex-wrap: nowrap;
  justify-content: center;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (min-width: 1201px) {
  .cart-step .breadcrumb {
    margin-bottom: 3em;
  }
}
.cart-step .breadcrumb .breadcrumb-item a {
  color: #b5b5b5;
}
.cart-step .breadcrumb .breadcrumb-item .cart-step-badge {
  background: #b5b5b5;
  margin-right: 10px;
}
.cart-step .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: var(--breadcrumb-padding-x);
}
.cart-step .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f285";
  font-family: bootstrap-icons !important;
  color: #000;
  float: none;
  padding-right: var(--breadcrumb-padding-x);
}
.cart-step .breadcrumb .breadcrumb-item.active a {
  color: #8cbb59;
}
.cart-step .breadcrumb .breadcrumb-item.active .cart-step-badge {
  background: #8cbb59;
}
.cart-grid {
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
  grid-template-columns: auto;
}
@media (min-width: 1201px) {
  .cart-grid {
    grid-template-columns: auto 125px 200px 115px 115px;
  }
  .cart-grid-02 {
    grid-template-columns: auto 125px 200px 115px;
  }
  .cart-grid-08 {
    grid-template-columns: repeat(8, auto);
  }
}
.cart-table {
  background-color: #f0efe9;
  padding: 1em 3em;
  border-radius: 0.5em;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (max-width: 1200px) {
  .cart-table {
    padding: 1em;
  }
}
.cart-table-img {
  max-width: 140px;
  overflow: hidden;
}
.cart-table-img img {
  border-radius: 0.5em;
}
@media (max-width: 1200px) {
  .cart-table-img {
    width: 4.75em;
    margin-top: 0.2em;
    height: auto;
    padding-bottom: 0;
    border-radius: 2px;
  }
}
.cart-table-info-head {
  --min-size: 18;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (max-width: 1200px) {
  .cart-table-info-head {
    margin-bottom: 0.35em;
    padding-right: 1em;
  }
}
.cart-table-info-txt {
  --min-size: 14;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (max-width: 1200px) {
  .cart-table-info-txt {
    line-height: 1.3;
  }
}
.cart-table table td {
  border: none;
}
@media (min-width: 1201px) {
  .cart-table table td {
    text-align: center;
  }
  .cart-table table td:first-child, .cart-table table td:nth-child(3) {
    text-align: left;
  }
}
@media (max-width: 1200px) {
  .cart-table table td {
    line-height: 1;
  }
}
.cart-table table thead tr {
  padding: 0.5em 1.5em;
}
@media (max-width: 1200px) {
  .cart-table table thead {
    display: none;
  }
}
.cart-table table tbody tr {
  background-color: #fff;
  padding: 1.5em;
}
.cart-table table tbody tr:not(:last-child) {
  margin-bottom: 1.5em;
}
@media (max-width: 1200px) {
  .cart-table table tbody tr {
    position: relative;
    padding: 0.25em;
  }
  .cart-table table tbody tr:not(:last-child) {
    margin-bottom: 0.5em;
  }
}
@media (max-width: 1200px) {
  .cart-table-td-price, .cart-table-td-num {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cart-table-td-cancel {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
  }
  .cart-table-td-info {
    width: 100%;
  }
  .cart-table .cart-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .cart-table .cart-grid-02 {
    position: relative;
  }
  .cart-table .cart-grid-02 .cart-table-td-info {
    padding-right: 2rem;
  }
  .cart-table .cart-grid-02 .cart-table-td-num {
    position: absolute;
    right: 2%;
    top: calc(50% - 0.25rem);
  }
  .cart-table .counter {
    width: 100%;
  }
}
.cart-table-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: #a4a4a2 1px dotted;
  padding: 1em 2em 2em 1em;
  margin-bottom: 1em;
}
@media (max-width: 1200px) {
  .cart-table-total {
    justify-content: space-between;
    padding-top: 0em;
    padding-right: 0;
    padding-bottom: 0.75em;
    margin-bottom: 1.5em;
  }
}
.cart-table-total-item {
  padding-right: 2em;
}
.cart-table-total-item-txt {
  font-size: 150%;
}
.cart-table-total-price {
  flex: none;
  text-align: right;
}
.cart-table-total-price-txt {
  font-size: 1.5em;
}
@media (min-width: 1201px) {
  .cart-table-total-price-txt {
    font-size: 36px;
  }
}
@media (min-width: 1201px) {
  .cart-table-total-list .cart-table-total-price, .cart-table-total-pay .cart-table-total-price {
    width: 200px;
  }
}
.cart-table-total-list {
  border-bottom: none;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  margin-bottom: 0;
}
.cart-table-total-list .cart-table-total-price-txt {
  font-size: 150% !important;
}
.cart-table-total-pay {
  margin-top: 1.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  border-top: #a4a4a2 1px dotted;
}
@media (max-width: 1200px) {
  .cart-table-total-pay {
    margin-top: 0.75em;
  }
}
.cart-table-btn li {
  max-width: 500px;
}
.cart-table-btn .btn {
  display: block;
  width: 100%;
  border-radius: 0.5em !important;
}
@media (min-width: 1201px) {
  .cart-table-btn .btn {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}
.cart-member-table {
  padding: 3em;
}
@media (max-width: 1200px) {
  .cart-member-table {
    padding: 2em 0 1em;
  }
}
.cart-checkout-info {
  margin-bottom: 15px;
}
@media (min-width: 1201px) {
  .cart-checkout-info {
    padding: 15px 0;
    margin-top: 15px;
  }
}
.cart-checkout-info table {
  width: 100%;
  table-layout: fixed;
}
.cart-checkout-info table tbody tr td {
  font-size: 14px;
  padding: 10px;
}
.cart-checkout-info table tbody tr td:first-child {
  color: #777;
  font-size: 18px;
}
@media (min-width: 1201px) {
  .cart-checkout-info table tbody tr td:first-child {
    width: 200px;
    font-size: 20px;
    padding-left: 2em;
  }
}
@media (max-width: 1200px) {
  .cart-checkout-info table tbody tr td:first-child {
    padding-left: 0;
  }
}
@media (max-width: 1200px) {
  .cart-checkout-info table, .cart-checkout-info table tbody, .cart-checkout-info table tr, .cart-checkout-info table td {
    display: block;
  }
  .cart-checkout-info table tbody tr td:first-child {
    padding-bottom: 0;
  }
}
.cart-checkout-info .form-control {
  font-size: 18px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  appearance: auto;
  background-color: #eeeeee;
}
.cart-checkout-info .form-control:focus {
  background-color: #fff;
}

.twzipcode_style .form-control * {
  background-color: transparent;
}
.twzipcode_style .form-control select {
  width: 100%;
  height: 100%;
}
.twzipcode_style .form-control input {
  border: none;
}
.twzipcode_style_flex .twzipcode_style {
  flex: 0 0 33%;
  max-width: 33%;
}

.contact-bg {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto;
  background-image: url("../images/contact_bgInfo.png");
}
@media (min-width: 1201px) {
  .contact-bg {
    padding-bottom: 120px;
  }
}
@media (min-width: 1251px) and (max-width: 1450px) {
  .contact-bg {
    padding-bottom: 140px;
  }
}
@media (max-width: 1080px) {
  .contact-bg {
    background-size: 100%;
    padding-bottom: 0;
  }
}
.contact-wrapper {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1.5em;
}
.contact-wrapper .btn-wrap {
  flex-wrap: wrap;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.contact-wrapper .btn-wrap .btn {
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 0.25em !important;
}
@media (min-width: 1201px) {
  .contact-wrapper .btn-wrap {
    justify-content: space-between;
  }
  .contact-wrapper .btn-wrap .btn {
    padding: 0.7rem 7rem;
  }
  .contact-wrapper .btn-wrap li {
    flex: none;
    margin-left: 0 !important;
  }
  .contact-wrapper .btn-wrap li.contact-btn-option {
    flex: 1;
  }
  .contact-wrapper .btn-wrap li.contact-btn-option .btn-wrap {
    justify-content: flex-end;
  }
}
@media (max-width: 1200px) {
  .contact-wrapper .btn-wrap li {
    text-align: center;
    margin: 0 5px !important;
  }
  .contact-wrapper .btn-wrap .btn {
    width: 100%;
  }
}
@media (min-width: 1201px) {
  .contact-wrapper .radio_style {
    margin-top: 0 !important;
  }
}
@media (max-width: 1200px) {
  .contact-wrapper textarea {
    min-height: 10em;
  }
  .contact-wrapper .contact-map {
    order: -1;
  }
}
.contact-info {
  overflow: hidden;
  margin-top: 2em;
  margin-bottom: 2em;
}
.contact-info-group dl dt {
  width: 140px;
  margin-bottom: 15px;
}
.contact-info-group dl dd {
  width: calc(100% - 140px);
  margin-bottom: 15px;
}
@media (min-width: 1201px) {
  .contact-info-group dl {
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .contact-info {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .contact-info-img {
    margin-bottom: 1em;
  }
}
.contact-form {
  width: 100%;
  padding: 1rem;
}
.contact-form-legend {
  padding-bottom: 1em;
}
.contact-form-legend legend {
  float: none;
  font-size: 18px;
}
@media (min-width: 1201px) {
  .contact-form-legend {
    padding: 0 1em;
  }
}
.contact-form .form-group {
  border: #dcd9d9 1px solid;
  padding: 10px 20px;
  margin-bottom: 20px;
}
@media (min-width: 1201px) {
  .contact-form .form-group {
    display: flex;
    align-items: start;
  }
  .contact-form .form-group > label {
    flex: none;
    width: 130px;
    color: #5d5d5e;
    border-right: #dcd9d9 1px solid;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.contact-form .form-control {
  border: none;
  border-radius: 0;
}
@media (max-width: 1200px) {
  .contact-form .form-control {
    background-color: #f5f5f5;
    margin-top: 0.35em;
  }
}
@media (min-width: 1201px) {
  .contact-form-flex, .contact-form-flex-list {
    align-items: center;
  }
}
.contact-btn-wrap {
  justify-content: space-between;
  padding-top: 0;
}
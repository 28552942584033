.member{
    &-form{
        &-inputbox{
            border: #d2d2d2 1px solid;
            border-radius: 0.5em;
            .form-group{
                .form-control{
                    padding-top: 1em;
                    padding-bottom: 1em;
                    padding-left: 1.5em;
                }
                .form-flex{
                    label{
                        min-width: 140px;
                    }
                }
            }
    
            // 登入用 & 會員專區
            &-login{
                .form-group{
                    &:first-child{
                        border-bottom: #d2d2d2 1px solid;
                    }
    
                    &:last-child{
                        border-top: #d2d2d2 1px solid;
                    }
                }
            }
        }
    
        &btn{
            .btn{
                font-size: 18px;
                border-radius: 0.5em !important;
                &-outline-main{
                    border-color: $colorMain !important;
                }

                @include setMinRwd{
                    padding-top: 0.75em;
                    padding-bottom: 0.75em;
                }
            }
        }
    }

    &-login-other{
        &-head{
            height: 1px;
            background: #cecece;
            position: relative;
            h3 {
                position: absolute;
                left: 50%;
                top: -10px;
                transform: translateX(-50%);
                color: #010101;
                background: #fff;
                text-align: center;
                line-height: 20px;
                font-size: 18px;
                padding: 0 10px;

                @include setMaxRwd{
                    min-width: 15em
                }
            }
        }
    
        &-social{
            margin-top: 2rem;
        }
    }


    // other
    &-disabled{
        &, .form-control:disabled {
            background-color: #e9ecef;
        }
    }
    
    &-borderTop{
        border-top: #d2d2d2 1px solid;
    }
    
    &-borderTottom{
        border-bottom: #d2d2d2 1px solid;
    }

    // 訂單表格
    &order-tablebox{

        &-thead{
            border: none !important;
            background-color: #fff !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            @include setMaxRwd{
                display: none;
            }
        }

        .cart-table{
            background-color: #fff;
            padding-left: 0;
            padding-right: 0;
            table{
                td{
                    font-size: 16px;
                    line-height: 1.25;
                    @include setMinRwd{
                        text-align: center !important;
                    }

                    @include setMaxRwd{
                        display: flex;
                        flex-wrap: wrap;
                        &:not(:last-child){
                            border-bottom: #e8dede 1px solid;
                        }

                        &::before{
                            content: attr(data-title);
                            display: inline-block;
                            min-width: 80px;
                            margin-right: 1rem;
                            margin-top: 0.15rem;
                            color: #777;
                            font-size: 0.875em;
                        }
                    }
                }

                tbody{
                    tr {
                        border:1px solid #cebfba;
                        @include radius(0.4em);
                        padding:0.25em 1em;
                        margin-bottom: 0.75rem !important;
                        &:nth-child(even){
                            background-color: #f6f3f2;
                        }

                        &.list-grid-7{
                            @include setMinRwd{
                                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.65fr;
                            }
                        }

                        @include setMaxRwd{
                            padding: 0 0.25em;
                        }
                    }
                }
            }
        }
    }

    // 訂單詳細頁
    &orderdetail-tablebox{
        .cart-table{
            @include setMinRwd {
                padding: 0.5em 1.5em;
            }
        }

        .cart-member-table{
            &-head{
                @include setMaxRwd{ 
                    font-size: calc(1rem + 0.6vw);
                    margin-bottom: 0.5rem !important;
                }
            }

            .cart-checkout-info table tbody tr{
                @include setMaxRwd{
                    display: flex;
                    align-items: center;
                    td{
                        line-height: 1.25;
                        padding-top: 0.25em !important;
                        padding-bottom: 0.25em !important;
                        &, *{
                            font-size: 16px !important;
                        }

                        &:first-child{
                            min-width: 100px;
                        }
                    }
                }
            }
        }
    }
}

// 密碼眼睛
.layui{
    &-input-inline {
        position: relative;
        .form-control{
            border:none
        }
        @include setMaxRwd{
            width: 100% !important;
        }
    }
    
    &-form-label {
        border-color: #e6e6e6;
        position: relative;
        left: 10px;
        top: -8px;
        font-size: 14px;
    }

    &-form-label {
        float: left;
        display: block;
        padding: 9px 15px;
        width: 80px;
        font-weight: 400;
        line-height: 20px;
        text-align: right
    }

    &-form-item {
        margin-bottom: 15px;
        clear: both;
        *zoom: 1;
        &::after {
            content: '\20';
            clear: both;
            *zoom: 1;
            display: block;
            height: 0
        }

        .layui-input-inline {
            float: left;
            width: 190px;
            margin-right: 10px
        }
    }
}

.x-red{
    color: red;
    position: relative;
    top: 3px;
}

.password{
    &, .n-txtCss {
        display: flex;
        align-items: center;
    }

    .eyes{
        @include setSize(31px,26px);
        position: absolute;
        display: inline-block;
        right: 11px;
        top: 14px;
        cursor: pointer;
        &-confirmpwd{
            right: -2.5em;
            @include setMaxRwd{
                right: 3em;
            }
        }
    }

    .none {
        display : none;
    }
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

// End
.pro{
    &-card-wrap{
        @include setMinRwd{
            margin-top: 3em;
        }

        @include setStyleMinRwd(1700px){
            margin-left: -2em;
            margin-right: -2em;
        }

        // 列表
        .card{
            background-color: transparent;
            overflow: hidden;

            &:hover{
                background-color: #fff;
            }
    
            &-img-top{
                @include radius(0.5em);
                overflow: hidden;
                height: 0;
                padding-bottom: 100%;
                img{ 
                    @extend %imgCenter;
                    transition: 0.25s;
                }
            }

            &-cart{
                position: absolute;
                width: 100%;
                height: 0;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding: 1em;
                padding-top: calc(100% - 1em);
                z-index: 2;
                @include setMinRwd{
                    opacity: 0;
                    width: auto;
                    height: auto;
                    top: 13vw;
                    right: 0;
                    padding-top: 1em;
                    pointer-events: auto;
                    &:hover{
                        opacity: 1;
                    }
                }

                @include setWidthRwd(1100px,1600px){
                    top: 10.5vw;
                }

                @include setMaxRwd{
                    opacity: 1;
                    svg, image{
                        width: 2.5em;
                        height: 2.5em;
                    }
                    width: 2.5em;
                    height: 2.5em;
                    padding-top: 0;
                    padding: 0;
                    right: 1em;
                    top: calc(100% - 129px);
                }
            }

            &-body{
                padding: 1em;
                @include setMaxRwd{
                    padding: 0.5em;
                }
            }
    
            // 
            &-title{
                --min-size: 16;
                --max-size: 20;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                margin-bottom: 0.25rem;
                @extend .fw-500;
                line-height: 1.05;
            }

            &-btn {
                width: auto;
                border-radius: 30px !important
            }

            &-linkNormal{
                @include setMinRwd{
                    &:hover,
                    &:focus{
                        & ~ .card-cart{
                            opacity: 1;
                        }

                        & ~ .card-img-top{
                            img{
                                transform: scale(1.25);
                            }
                        }

                        & ~ .card-body{
                            .card-btn{
                                background-color: $colorMain !important;
                            }
                        }
                    }
                }
            }

            @include setMinRwd{
                margin-bottom: 3em;
            }
        }
    }
}

// 價格
.price{
    // 原價
    &-old{
        text-decoration: line-through;
        color: #777;
        --min-size: 16;
        --max-size: 18;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
    }

    // 特or現價
    &-cur{
        --min-size: 24;
        --max-size: 25;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        @extend .fw-600;
        & ~ .price-old{
            padding-left: 10px;
        }
    }
}
:root{
    --breadcrumb-padding-x:1.25rem;
    @include setMaxRwd{
        --breadcrumb-padding-x:0.25rem;
    }
}


.cart{
    // 清單步驟
    &-step{
        &-badge{
            @include setSize(40px,40px);
            line-height: 39px;
            font-size: 18px;
            font-weight: 100;
            background: #000;
            @include radius(50%);
            color: #fff;
            padding: 0;

            @include setMaxRwd{
                @include setSize(30px,30px);
                line-height: 29px;
                font-size: 15px;
            }
        }
    
        .breadcrumb{
            flex-wrap: nowrap;
            justify-content: center;
            
            --min-size: 16;
            --max-size: 18;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            @include setMinRwd{
                margin-bottom: 3em;
            }
    
            .breadcrumb-item{
                a{
                    color: #b5b5b5;
                }
    
                .cart-step-badge{
                    background: #b5b5b5;
                    margin-right: 10px;
                }
    
                & + .breadcrumb-item{
                    padding-left: var(--breadcrumb-padding-x);
                    &::before{
                        content: '\F285';
                        font-family: bootstrap-icons !important;
                        color: #000;
                        float: none;
                        padding-right: var(--breadcrumb-padding-x);
                    }
                }
    
                &.active {
                    a{
                        color: $mainthird;
                    }
    
                    .cart-step-badge{
                        background: $mainthird;
                    }
                }
            }
        }
    }

    // 用於購物車清單
    &-grid{
        display: grid;
        align-items: center;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;
        grid-template-columns: auto ;
        @include setMinRwd{
            grid-template-columns: auto 125px 200px 115px 115px;
            &-02{
                grid-template-columns: auto 125px 200px 115px;
            }
            &-08{
                grid-template-columns: repeat(8, auto);
            }
        }
    }

    // 清單表格
    &-table{
        background-color: #f0efe9;
        padding: 1em 3em;
        @include radius(0.5em);

        --min-size: 16;
        --max-size: 18;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

        @include setMaxRwd{
            padding: 1em;
        }

        &-img{
            max-width: 140px;
            overflow: hidden;
            img{
                @include radius(0.5em);  
            }

            @include setMaxRwd{
                width: 4.75em;
                margin-top: 0.2em;
                height: auto;
                padding-bottom: 0;
                border-radius: 2px;
            }
        }

        &-info{
            &-head{
                --min-size: 18;
                --max-size: 24;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                @include setMaxRwd{ 
                    margin-bottom: 0.35em;
                    padding-right: 1em;
                }
            }
            &-txt{
                --min-size: 14;
                --max-size: 16;
                --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                @include setMaxRwd{
                    line-height: 1.3;
                }
            }
        }

        // 摻雜Grid
        table{
            td{
                border: none;
                @include setMinRwd{
                    text-align: center;
                    &:first-child, &:nth-child(3){
                        text-align: left;
                    }
                }

                @include setMaxRwd{
                    line-height: 1;
                }
            }

            thead{
                tr{
                    padding: 0.5em 1.5em;
                }
                
                @include setMaxRwd{
                    display: none;
                }
            }

            tbody{
                tr{ 
                    background-color: #fff;
                    padding: 1.5em;
                    &:not(:last-child){
                        margin-bottom: 1.5em;
                    }

                    @include setMaxRwd{
                        position: relative;
                        padding: 0.25em;
                        &:not(:last-child){
                            margin-bottom: 0.5em;
                        }
                    }
                }
            }
        }

        @include setMaxRwd{

            &-td{
                // 單價 & 數量
                &-price,
                &-num{
                    flex:0 0 50%;
                    max-width: 50%;
                }
                // 刪除格
                &-cancel{
                    position: absolute;
                    top: 0;
                    right: 0;
                    @include setSize(40px,40px);
                }
                
                // 內容
                &-info{
                    width: 100%;
                }
            }

            .cart-grid{
                display: flex;
                flex-wrap: wrap;
                &-02{
                    position: relative;
                    .cart-table-td-info{
                        padding-right: 2rem;
                    }

                    .cart-table-td-num{
                        position: absolute;
                        right: 2%;
                        top: calc(50% - 0.25rem);
                    }
                }
            }

            .counter {
                width: 100%;
            }
        }
        
        // 總計
        &-total{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom: #a4a4a2 1px dotted;
            padding: 1em 2em 2em 1em;
            margin-bottom: 1em;

            @include setMaxRwd{
                justify-content: space-between;
                padding-top: 0em;
                padding-right: 0;
                padding-bottom: 0.75em;
                margin-bottom: 1.5em;
            }

            &-item{
                padding-right: 2em;
                &-txt{
                    font-size: 150%;
                }
            }

            &-price{
                flex: none;
                text-align: right;
                &-txt{
                    font-size: 1.5em;
                    @include setMinRwd{ font-size: 36px; }
                }
            }

            &-list,
            &-pay{
                .cart-table-total-price {
                    @include setMinRwd{
                        width: 200px;
                    }
                }
            }

            &-list{
                border-bottom: none;
                padding-top: 0.25em;
                padding-bottom: 0.25em;
                margin-bottom: 0;
                
                .cart-table-total-price-txt {
                    font-size: 150% !important;
                }
            }

            &-pay{
                margin-top: 1.5em;
                padding-top: 0.25em;
                padding-bottom: 0.25em;
                border-top: #a4a4a2 1px dotted;
                @include setMaxRwd{
                    margin-top: 0.75em;
                }
            }
        }

        &-btn{
            li{
                max-width: 500px;
            }

            .btn{
                display: block;
                width: 100%;
                border-radius: 0.5em !important;
                @include setMinRwd{
                    // min-width: 500px;
                    padding-top: 0.65em;
                    padding-bottom: 0.65em;
                }
            }
        }
    }

    &-member-table{
        padding: 3em;
        @include setMaxRwd{
            padding: 2em 0 1em;
        }
    }

    // 
    &-checkout-info {
        margin-bottom: 15px;
        @include setMinRwd{
            padding: 15px 0;
            margin-top: 15px;
        }
    
        table {
            width: 100%;
            table-layout: fixed;
            tbody {
                tr {
                    td {
                        font-size: 14px;
                        padding: 10px;
                        &:first-child{
                            color: #777;
                            font-size: 18px;
                            @include setMinRwd{
                                width: 200px;
                                font-size: 20px;
                                padding-left: 2em;
                            }

                            @include setMaxRwd{
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            @include setMaxRwd{
                &, tbody, tr, td{
                    display: block;
                }

                tbody{
                    tr{
                        td{
                            &:first-child{
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .form-control{
            font-size: 18px;
            padding-top: 1rem;
            padding-bottom: 1rem;
            appearance: auto;
            background-color: #eeeeee;
            &:focus{
                background-color: #fff;
            }
        }
    }
}

.twzipcode_style{
    .form-control{
        *{
            background-color: transparent;
        }

        select{
            width: 100%;
            height: 100%;
        }

        input{
            border: none;
        }
    }

    &_flex{
        .twzipcode_style{
            flex: 0 0 33%;
            max-width: 33%;
        }
    }
}

@mixin fontSize($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// @mixin grid-($num){
//     .list-grid-($num){
//         display: grid;
//         align-items: center;
//         grid-column-gap: 0;
//         grid-auto-rows: 0fr 0fr 0fr;
//         grid-template-columns: auto ;
//         @include setMinRwd{
//             &-($num){
//                 grid-template-columns: repeat(8, auto);
//             }
//         }
//     }
// }

@mixin listGrid-($num) {
    display: grid;
    align-items: center;
    grid-column-gap: 0;
    grid-auto-rows: 0fr;
    grid-template-columns: auto;
    @include setMinRwd{
        grid-template-columns: repeat($num, auto);
    }
}



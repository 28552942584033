:root{
    --headingItem : #040404;
    --headingDes: #535353;
}

// 產品項目說明
.pro-heading{
    // &-des{
    //     border-bottom: 1px solid #676767;
    //     margin-top: 20px;
    //     margin-bottom: 45px;
    // }

    &-num{
        --min-size: 16;
        --max-size: 24;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

        color: var(--headingItem);
        font-family: "good-times", sans-serif;
    }

    &-txt{
        --min-size: 16;
        --max-size: 18;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);

        color: var(--headingItem);
    }

    &-des{
        color: var(--headingDes);
        border-top: 1px solid #bfbfbf;
        padding-top: 25px;
        margin-top: 25px;
        margin-bottom: 45px;
    }
}

// 產品詳細頁
.Prod{
    &-conInfo{
        @include setMinRwd{
            display: flex;
            align-items: flex-start;
            margin-top: 3.5em;
            padding-left: 1.5em;
            padding-right: 1.5em;
        }

        &-img{
            overflow: hidden;

            .swiper {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &-slide {
                    background-color: #fff;
                    overflow: hidden;
                    img {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        max-height: 100%;
                        margin: auto;
                        @include radius(0.5em);
                    }
                }

                &-button{
                    display: none;
                    width: 100%;
                    z-index: 1;
                    @include setMinRwd{
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        height: 100%;
                        position: absolute;
                        left: 50px;
                        &-prev,
                        &-next{
                            margin: 0;
                            transform: rotate(90deg);
                        }
                    }

                    @include setWidthRwd(1100px,1600px){
                        left: 25px;
                    }

                    &-next,
                    &-prev {
                        position: static;
                        // color: #fff;
                        // background: #040404;
                        color: $colorMain;
                        @include setSize(40px,40px);
    
                        &::after{
                            @include fontSize(20px);
                        }
                    }
                }
            }

            @include setMinRwd{
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-start;
                width: 100%;
                height: 41em;
                padding-right: 4em;

                .swiper{
                    &.Prod_mySwiper2{
                        width: 85%;
                        order: 1;
                        padding-left: 1em;
                    }
    
                    &.Prod_mySwiper{
                        width: 15%;
                        height: calc(100% - 4em);
                        margin: 2em 0;
                    }
                }
            }

            @include setWidthRwd(1100px,1600px){
                height: 36em;
                padding-right: 1.5em;
            }

            @include setMaxRwd{
                margin-top: 1em;
            }
            
            // 大圖
            .Prod_mySwiper2 {
                height: 80%;
                width: 100%;
                margin-bottom: 0.25em;
                .swiper {
                    &-slide {
                        padding-bottom: 100%;
                    }
                }

                @include setMinRwd{
                    height: 100%;
                    .swiper {
                        &-slide {
                            padding-bottom: 70%;
                        }
                    }
                }
            }
            
            // 縮略圖
            .Prod_mySwiper {
                height: 20%;
                box-sizing: border-box;
                padding: 10px 0;
                .swiper{
                    &-slide {
                        opacity: 0.5;

                        &-thumb-active {
                            opacity: 1;
                        }

                        // @include setMinRwd{
                        //     height: 0 !important;
                        //     padding-bottom: 100%;
                        // }

                        @include setMaxRwd{
                            padding-bottom: 29%;
                        }
                    }
                }

                @include setMinRwd{
                    height: 100%;
                    padding: 0;
                }
            }
        }

        &-info{
            padding-top: 25px;
            @include setMinRwd{
                flex: 1;
            }
        }
    }

    &-heading{
        display: flex;
        line-height: 1.5;
        padding-bottom: 0.25em;
        margin-bottom: 0;
        font-size: 24px;

        &Title{
            font-size: 16px;
            margin-bottom: 0.5rem;

            @extend %setFlex;

            &-item{
                color: #777;
                padding-right: 1em;
            }

            @include setMinRwd{
                margin-bottom: 20px;
                &-item{
                    padding-left: 2em;
                    padding-right: 2em;
                }
            }
        }
    }

    &-conTxt{
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        &.total{
            border: none;
            padding: 0;
            margin: 0;
            .Prod-headingTitle-item{
                color: #000
            }
        }
    }

    &-conSpec{
        @extend %setFlex;
        text-align: center;
        &-item{
            flex-shrink: 1;
            margin-bottom: 15px;
            &Img{
                img{
                    max-width: 40px;
                }
            }

            &Number{
                border-bottom: #cecece 1px solid;
                &_count{
                    color: #626262;
                }
            }

            div{
                padding-left: 10px;
                padding-right: 10px;
                margin-bottom: 5px;
                padding-bottom: 2px;
            }
        }
    }

    &-socialIcons {
        display: flex;
        margin-top: 30px;
        li {
            margin-right: 10px;
        }
    }

    &-unreset{
        padding-top: 1.5em;
        @include setMinRwd{
            margin-top: 4.5em;
        }

        .nav-tabs{
            border-top: #bfbfbf 1px solid;
            border-bottom: 1px #bfbfbf solid;
            @include setMinRwd{
                padding-left: get-vw(100px);
                padding-right: get-vw(100px);
            }
        }

        .nav-item {
            min-height: auto;
            &::before{
                display: none !important;
            }
        }

        .nav-link{
            position: relative;
            @include fontSize(16px);
            color: #7d7c7c;
            @extend .fw-400;
            background-color: transparent !important;
            border: none;
            padding-right: 30px !important;
            padding-left: 30px !important;

            &.active{
                color: #a3938c !important;
                &::after {
                    content: "";
                    display: block;
                    @include setSize(70%,3px);
                    background-color: $colorMain;
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            @include setMinRwd{
                @include fontSize(18px);
                line-height: 2.5;
            }
        }

        .tab-content{
            padding: 1em;
            @include setMinRwd{
                padding: 4em 2em;
            }
        }
    }

    &-btn{
        justify-content: center;
        .btn{
            border-radius: 0.3em !important;
            border-color: currentColor !important;
            @include setMinRwd{
                font-size: 18px;
                padding: get-vw(8px) get-vw(32px);
            }

            @include setMaxRwd{
                flex: 1
            }
        }
    }
}

// 計算機
.counter{
    @extend %setFlex-center;
    width: 150px;

    border: #e5e5e5 1px solid;
    @include radius(0.25em);
    text-align: center;
    .list{
        flex: 1;
        margin: 0;
        padding: 0.5em 0;
    }

    a{
        display: block;
    }

    .add{

    }

    .numtxt{
        font-size: 18px;
    }

    .minus{

    }
}
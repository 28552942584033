.about{
    &-incontent{
      padding:3em 1em;
      overflow: hidden;

      &-style01{
        @include setBackSet(top,cover);
        background-image: url('../images/about-cont-style01.png');
        padding-bottom: 24em;
        @include setMaxRwd{
          padding-bottom: 16em !important;
        }
      }

      &-style02{
        background-color: #f6f5f5;
        z-index: -1;

        @include setMinRwd{
          margin-top: -6em;
          padding-bottom: 6em;
        }

        @include setMaxRwd{
          padding-bottom: 3em !important;
        }
      }
      
      &-style01,
      &-style02{
        padding-top: 9em;
        @include setMaxRwd{
          padding-top: 4em;
        }
      }

      @include setWidthRwd(1200px,1600px){
        padding-left: 3em;
        padding-right: 4em;
      }

      @include setMaxRwd{
        padding: 1.5em;
      }
    }

    &-awards{
      &-txt{
        position: relative;
        --min-size: 18;
        --max-size: 20;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        color: #b89c8c;
        border-bottom:transparent 1px solid;

        &:not(:last-child){
          margin-bottom: 0.75em;
        }

        .txt_num{
          position: relative;
          z-index: 1;
          font-family: "turbinado-pro", sans-serif, Arial;
          --min-size: 22;
          --max-size: 30;
          --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
          font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
          padding-right: 1em;
          color: #b19583;
          &::after{
            content: '';
            display: block;
            @include setSize(25px,25px);
            background-color: #dfd9d1;
            border-radius: 50%;
            position: absolute;
            left: 5px;
            top: -5px;
            z-index: -1;
            opacity: 0;
          }
        }

        .txt_arrow_right{
          position: absolute;
          right: 0;
          opacity: 0;
        }
        
        &:hover,
        &.active{
          color: $colorMain;
          border-color: $colorMain;
          .txt_num{
            &::after{
              opacity: 1;
            }
          }

          .txt_arrow_right{
            opacity: 1;
          }
        }
      }

      &-wrapper{
        z-index: 1;
      }

      &-list{
        z-index: 1;
        &-img{
          position: absolute;
          left: -2.5em;
          top: 85%;
          width: 775px;
          @include setMaxRwd{
            left: 0;
            top: auto;
            bottom: -100%;
            width: 100%;
          }
        }

        @include setMaxRwd{
          margin-bottom: 1em;
        }
      }

      &-img{
        .img{
          &.d-block{
            display: block !important;
          }
        }
        @include setMaxRwd{
          margin-top: 1em;
        }
      }
    }

    &-titlebox{
      &-head{
        --min-size: 22.5;
        --max-size: 30;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
      }

      &-title{
        --min-size: 20;
        --max-size: 24;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
      }

      &-num{
        font-family: $Allura, Arial;
        --min-size: 25;
        --max-size: 60;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        // color: $mainthird;
        line-height: 0.85;
        &::after{
          content: '';
          display: block;
          width: 100%;
          height:100%;
          max-width: 140px;
          min-height: 140px;
          @include radius(50%);
          background-color: #f6f3f2;
          position: absolute;
          top: -30px;
          z-index: -1;
          left: -40px;
        }

        @include setMaxRwd{
          &::after{
            max-width: 70px;
            min-height: 70px;
            left: -20px;
            top: -25px;
          }
        }
      }
    }

    &-articlebox{
      @include setMinRwd{
        padding-left: 4rem;
      }
    }

    &-stepbox{
      margin-top: 0.75em;
      margin-bottom: 1.5em;
      @include setMaxRwd{
        .about-titlebox{
          margin-top: 0.5em;
        }

        .about-articlebox{
          margin-bottom: 4em;
        }
      }
    }

    &-step-path{
      position: absolute;
      &-01{
        bottom: -12em;
        right: 6em;
      }

      &-02{
        top: 80%;
        left: calc(115px / 2);
      }

      &-03{
        bottom: calc(-115px / 2);
        left: 50%;
        width: 115px;
      }

      &-04{
        bottom: calc(-115px * 1.5);
        right: calc(115px);
      }

      &-05{
        left: calc(47% - 115px);
        top: calc(50% + 115px);
      }

      &-06{
        right: calc(115px);
        top: calc(80% + 115px);
      }

      &-07{
        top: 85%;
        left: 57.5px;
      }

      &-08{
        bottom: -172.5px;
        right: calc(115px / 3);
      }
      
      .path{
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 2.5s linear alternate infinite;
      }

      @include setMaxRwd{
        display: none;
      }
    }
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
    fill-opacity: 0;
  }
  to {
    fill-opacity: 1;
    stroke-dashoffset: 0;
  }
}

.aboutMax-img-140p{
  @include setMinRwd{
    width: calc(100% + 40%);
    left: -30%;
  }
}